import "./navigation.component.scss";

import * as React from 'react';

import { Avatar, Badge, Button, CircularProgress, InputLabel, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { Check, ErrorOutline, Logout, Settings, WarningAmber } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import AddIcon from "@mui/icons-material/Add";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { Close } from "@mui/icons-material";
import CssBaseline from '@mui/material/CssBaseline';
import CustomSnackbarNew from '../customSnackBar/customSnackBarNew.component';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from '@mui/material/Divider';
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import Drawer from '@mui/material/Drawer';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import IconButton from '@mui/material/IconButton';
import InfoIcon from "@mui/icons-material/Info";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { LoaderStateType } from "../../types/common.types";
import MenuIcon from '@mui/icons-material/Menu';

import NotificationsIcon from "@mui/icons-material/Notifications";

import { RootState } from "../../stores/store";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { generalFeedback } from '../../services/questions.service';
import { isMobile } from "react-device-detect";
import logo from "../../logo.svg";
import { updateCurrentRole } from "../../stores/slices/navigation.slice";

//import { LoaderStateType } from "../../../types/common.types";











interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  windows?: () => Window;
}


const drawerWidth = 240;
const navItems = ['Home', 'About', 'Contact'];
//const isMobile= window.matchMedia('(max-width: 767px)');
export default function NavigationComponent(props: Props) {
  const env = process.env;
  const navigate = useNavigate();
  const notifications: any[] = [
    {
      id: "notification-1",
      text: "Background check complete.",
      type: "success",
    },
    {
      id: "notification-2",
      text: "All engines are working fine.",
      type: "success",
    },
    {
      id: "notification-3",
      text: "You appear to be on a slow network.",
      type: "warning",
    },
  ];

  const navigationReducer = useSelector((state: RootState) => state.navigation);
  const location = useLocation();
  const dispatch = useDispatch();
  const questionReducer = useSelector((state: RootState) => state.question);
  const [appTitle, setAppTitle] = React.useState<string>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openMenu, updateOpenMenu] = React.useState<boolean>(false);
  const [menuType, setMenuType] = React.useState<string>("");
  const [feedback, setFeedback] = React.useState("Suggestions for Improvement");
  const [snackbarType, updateSnackbarType] = React.useState<string>("");
  const [snackbarMessage, updateSnackbarMessage] = React.useState<string>("");
  const [openSnackbar, updateOpenSnackbar] = React.useState<boolean>(false);

  const handleFeedbackChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFeedback(e.target.value);
  };
  const [dialogState, updateDialogState] =
    React.useState<LoaderStateType>("success");
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)
  const handlefeedback = () => {
    setIsDialogOpen(true);
  }
  let [userInfo, setUserInfo] = React.useState({
    userId: "test@sce.com",
    sessionId: "",
  });
  let userName = "Test Test";

  let email = sessionStorage.getItem("email");

  if (email !== undefined && email != null) {
    userInfo.userId = email;
  }
  let name = sessionStorage.getItem('username');
 

  if (name != undefined && name != null) {
    userName = name;
    name = name?.split(' ').map(word => word.charAt(0)).join('');
  }
  let storedSession = sessionStorage.getItem("sessionId");

  if (storedSession !== null && storedSession !== undefined) {
    userInfo.sessionId = storedSession;
  }
  const handleClose = () => {
    updateOpenMenu(false);
    setAnchorEl(null);
  };
  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    console.log(event);
    if (reason === "clickway") {
      return;
    }

    updateOpenSnackbar(false);
  };
  React.useEffect(() => {
    const { appName } = navigationReducer;
    setAppTitle(appName);
  }, [navigationReducer]);

  React.useEffect(() => {
    dispatch(updateCurrentRole());
  }, [location, dispatch]);

  const openNavigationMenu = (
    event: React.MouseEvent<HTMLElement>,
    menuType: string
  ) => {
    setMenuType(menuType);
    setAnchorEl(event.currentTarget);
    updateOpenMenu(true);
  };
  const { windows } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

 

 
  return (
   

    
      <>
        <Box sx={{ flexGrow: 1 }}>

          <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar>
              {<img
                src={logo}
                alt="Logo"
                className="logo-image"
                onClick={() => {
                  navigate("/");
                }}
              />}
              <Divider
                orientation="vertical"
                flexItem
                className="divider"
                variant="middle"
              />
              <Typography variant="h6" textAlign="center" component="div" sx={{ flexGrow: 1 }}>
                {appTitle}
              </Typography>

              {/* {navigationReducer.currentRole === "normal" && (
              <Button
                color="secondary"
                variant="text"
                startIcon={<AddCommentIcon />}
                sx={{ mr: 1 }}
                size="medium"
                onClick={initiateNewConversation}
              >
                <span className="button-text">New Conversation</span>
              </Button>
            )} */}
              {navigationReducer.dashBoard && (
              <Button

                color="inherit"
                variant="text"
                startIcon={<FeedbackOutlinedIcon />}
                sx={{ mr: 1, border: '2px solid #F7D35E', }}
                size="medium"
                onClick={handlefeedback}
              >
                <span className="button-text">Feedback</span>
              </Button>
        )}
              <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} maxWidth="md"
                PaperProps={{
                  sx: {

                    width: "436px",
                    height: "353px"
                  },
                }}>
                <DialogTitle >{<b style={{ fontSize: "18px" }}>Give Feedback</b>}<Button onClick={() => setIsDialogOpen(false)}><Close sx={{ color: "black", marginLeft: "942%" }} /></Button>  </DialogTitle>
                {dialogState === "loading" && (
                  <DialogContent sx={{ height: 300 }}>
                    <div className="col-12 dialog-loader-container">
                      <CircularProgress color="secondary" />
                    </div>
                  </DialogContent>
                )}
                {(dialogState === "success" || dialogState === "error") && (
                  <>
                    <DialogContent style={{ height: 225 }}>
                      {/* <DialogContentText fontFamily ="Segoe UI" fontSize="14px" color="#19191D">  */}
                      {<div>
                        <div className="btts">
                          <label><input type="radio" value="Suggestions for Improvement" name="userinput"  checked={feedback === "Suggestions for Improvement"} onChange={handleFeedbackChange} defaultChecked={true} /> Suggestions for Improvement</label></div>
                        <div className="btts">
                          <label><input type="radio" value="Bugs/Issues" name="userinput" checked={feedback === "Bugs/Issues"} onChange={handleFeedbackChange} /> Bugs/Issues</label></div>
                        <div className="btts"><label><input type="radio" value="Positive Feedback" name="userinput" checked={feedback === "Positive Feedback"} onChange={handleFeedbackChange} /> Positive Feedback</label></div>
                        <div className="btts">
                          <label><input type="radio" value="General/Other Feedback" name="userinput" checked={feedback === "General/Other Feedback"} onChange={handleFeedbackChange} /> General/Other Feedback</label></div>
                        <div className="feedback"><label >Additional Details</label></div><textarea placeholder="Type your message" id="myTextArea" rows={3} cols={60}>
                        </textarea>
                      </div>}
                      {/* </DialogContentText> */}
                    </DialogContent>
                    <DialogActions sx={{ mb: 3, mr: 2 }}>
                      <Button variant="contained" sx={{ background: "#FFFFFF", color: "#19191D" }} onClick={() => setIsDialogOpen(false)}>Close</Button>
                      <Button
                        onClick={() => {


                          const textareaElement = document.getElementById('myTextArea') as HTMLTextAreaElement;
                          const remarks = textareaElement.value;

                          generalFeedback(userInfo.userId, userInfo.sessionId, userName, feedback, remarks)

                            .then(response => {

                              updateSnackbarMessage("Thank you for your feedback!");
                              updateSnackbarType("success");

                              updateOpenSnackbar(true);
                            })
                            .catch(error => {
                              updateSnackbarMessage("Unable to update feedback");
                              updateSnackbarType("error");
                            
                              updateOpenSnackbar(true);
                            });

                          setIsDialogOpen(false);

                        }}
                        variant="contained"
                        sx={{ background: "#00664F", color: "#FFFFFF" }}
                        id="savelike"
                      >
                        Save
                      </Button>


                    </DialogActions>

                  </>

                )}

              </Dialog>

              {navigationReducer.currentRole === "normal" && (
                <Button

                  color="inherit"
                  variant="text"
                  startIcon={<AddIcon />}
                  sx={{ mr: 1, border: '2px solid #F7D35E', }}
                  size="medium"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  <span className="button-text">New Session</span>
                </Button>
              )}


              {/* {navigationReducer.currentRole === "normal" &&
                questionReducer.questions &&
                questionReducer.questions.length > 0 && <ReportDialogComponent />}
              {navigationReducer.currentRole === "admin" && (
                <Button
                  color="secondary"
                  variant="text"
                  startIcon={<DownloadForOfflineIcon />}
                  sx={{ mr: 1 }}
                  size="medium"
                  onClick={() => {
                    window.open(
                      `${env.REACT_APP_API_URL}/download_logs?`,
                      "_blank"
                    );
                  }}
                >
                  <span className="button-text">Download Logs</span>
                </Button>
              )}
              {navigationReducer.currentRole === "admin" && (
                <Button
                  color="secondary"
                  variant="text"
                  startIcon={<DownloadForOfflineIcon />}
                  sx={{ mr: 1 }}
                  size="medium"
                  onClick={() => {
                    window.open(`${env.REACT_APP_API_URL}/download_reports?`);
                  }}
                >
                  <span className="button-text">Download Report</span>
                </Button>
              )} */}

              {/*             <IconButton
              color="inherit"
              sx={{ marginRight: "10px" }}
              onClick={($event) => {
                openNavigationMenu($event, "notifications");
              }}
            >
              <Badge color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}

              <IconButton
                color="primary"
                onClick={($event) => {
                  // openNavigationMenu($event, "profile");
                }}
              >
                <Avatar
                  sx={{
                    backgroundColor: "#FBB04C6A",
                    //</IconButton> bgcolor: "white",
                    color: "#FBB04C",
                    width: "30px",
                    height: "30px",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  {name}
                </Avatar>
              </IconButton>
            </Toolbar>
          </AppBar>
          {/* <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav> */}

        </Box>
        {menuType === "profile" && (
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openMenu}
            onClose={handleClose}
            onClick={handleClose}
            slotProps={{
              paper: {
                elevation: 0,
                sx: {
                  width: 175,
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 24,
                    height: 24,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={handleClose}>
              <Avatar sx={{ width: 20, height: 20 }} /> John Doe
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <InfoIcon fontSize="small" />
              </ListItemIcon>
              Version: 1.0
            </MenuItem>
          </Menu>
        )}

        {menuType === "notifications" && (
          <Menu
            anchorEl={anchorEl}
            id="notifications-menu"
            open={openMenu}
            onClose={handleClose}
            onClick={handleClose}
            slotProps={{
              paper: {
                elevation: 0,
                sx: {
                  minWidth: 155,
                  maxWidth: 355,
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {notifications.map((notification: any) => (
              <MenuItem
                onClick={handleClose}
                key={notification.id}
                className="text-wrap"
              >
                {notification.type === "success" && (
                  <Check color="success" sx={{ marginRight: "5px" }} />
                )}
                {notification.type === "error" && (
                  <ErrorOutline color="error" sx={{ marginRight: "5px" }} />
                )}
                {notification.type === "warning" && (
                  <WarningAmber color="warning" sx={{ marginRight: "5px" }} />
                )}
                {notification.text}
              </MenuItem>
            ))}
          </Menu>
        )}
        <CustomSnackbarNew
        openSnackbar={openSnackbar}
        type={snackbarType}
        message={snackbarMessage}
        handleSnackBarClose={handleSnackbarClose}
      />
      </>
  );
}
