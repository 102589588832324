
import "./home.component.scss";
import * as React from "react";

import { Box, Button, CSSObject, CssBaseline, Divider, IconButton, InputBase, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Theme, styled } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { QuestionInterface, QuestionRatingType, RateResponse } from "../../interfaces/question.interface";
import {
  addNewQuestion,
  updateRating,
  updaterateresponse,
} from "../../stores/slices/questions.slice";
import { clearSession, getAnswerService, rateQuestionService, gethistory, getsession, getrename, getchathistory, searchhistory } from "../../services/questions.service";
import { getDbHistory, newConversationInitiaited } from "../../services/history.service";
import { useDispatch, useSelector } from "react-redux";
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import { Apartment } from "@mui/icons-material";
import { Calculate } from "@mui/icons-material";
import { Contacts } from "@mui/icons-material";
import CustomSnackbar from "../customSnackBar/customSnackBar.component";
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { History } from "@mui/icons-material";
import { Link } from "@mui/material";
import LoaderComponent from "../loader/loader.component";
import { LoaderStateType } from "../../types/common.types";
import { Menu } from "@mui/icons-material";
import MuiDrawer from '@mui/material/Drawer';
import { Newspaper } from "@mui/icons-material";
import QuestionSectionComponent from "./questionSection/questionSection.component";
import { RootState } from "../../stores/store";
import SendIcon from "@mui/icons-material/Send";
import { TextSnippet } from "@mui/icons-material";
import { Tooltip, Typography } from "@mui/material";
import { TrendingUp } from "@mui/icons-material";
import WelcomeScreenComponent from "./welcomeScreen/welcomeScreen.component";

import { ZoomIn } from "@mui/icons-material";
import {
  addNewQuestion as addNewQuestionToHistory,
} from "../../stores/slices/history.slice";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import axios from "axios";
import { isMobile } from "react-device-detect";
import { v4 as uuidv4 } from 'uuid';
import FolderIcon from '@mui/icons-material/Folder';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Popover from '@mui/material/Popover';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LaunchIcon from '@mui/icons-material/Launch';
import HtmlTooltip from "../htmlTooltip/htmlTooltip.component";
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HistoryDrawerComponent from "../historyDrawer/historyDrawer.component";
const drawerWidth = 250;
const drawerWidth1 = 300;

type ResponseType = {
  history: {
    [date: string]: {
      session_name: string;
      session_id: string;
    }[];
  };
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  overflowY: 'hidden',
  border: 'none',
  boxShadow: "1px 0px 6px #00000029",
  position: 'relative',
  background: '#247B68',
  // boxShadow: "1px 0px 6px #00000029",
});

const openedMixin1 = (theme: Theme): CSSObject => ({
  width: drawerWidth1,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  // overflowY: 'hidden',
  // top: '63px',
  
  left: '1px',
  position: 'relative',
  height: '100vh',
  borderBottomWidth:'1px ',
  borderRight: '1px solid #00664F',
  borderBottomColor:'#00664F',
  // border: 'none',
  // boxShadow: "1px 0px 6px #00000029",
  // position:'static',
  // top:'119px'

  // boxShadow: "1px 0px 6px #00000029",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  overflowY: 'hidden',
  border: 'none',
  boxShadow: "1px 0px 6px #36363629",
  position: 'relative',
  background: '#247B68',
  // boxShadow: "1px 0px 6px #00000029",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const closedMixin1 = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  overflowY: 'hidden',
  border: 'none',
  boxShadow: "1px 0px 6px #36363629",
  left: '-1px',
  
  // position:'relative',
  // position:'static',
  // boxShadow: "1px 0px 6px #00000029",
  // width: `calc(${theme.spacing(7)} + 1px)`,
  // [theme.breakpoints.up('sm')]: {
  //   width: `calc(${theme.spacing(8)} + 1px)`,
  // },
  width: `calc(0px + 1px)`
});

// const detectMobile = () => {
//   navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i)|| navigator.userAgent.match(/Windows Phone/i)? 
//     true: false
//   };



const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);
const Drawer_new = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth1,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin1(theme),
      '& .MuiDrawer-paper': openedMixin1(theme),
    }),
    ...(!open && {
      ...closedMixin1(theme),
      '& .MuiDrawer-paper': closedMixin1(theme),
    }),
  }),
);
const HomeComponent = () => {
  const questionReducer = useSelector((state: RootState) => state.question);
  const dispatch = useDispatch();
  const questionPlaceHolder: string = "Ask your question.";
  const [loadingState, updateLoadingState] =
    React.useState<LoaderStateType>("loading");
  const [questionText, updateQuestionText] = React.useState<string>("");
  const [parentquestionid, setParentQuestionId] = React.useState<string>("");
  const [snackbarType, updateSnackbarType] = React.useState<string>("");
  const [snackbarMessage, updateSnackbarMessage] = React.useState<string>("");
  const [openSnackbar, updateOpenSnackbar] = React.useState<boolean>(false);
  const [requestSent, updateRequestSent] = React.useState<boolean>(false);
  const historyReducer = useSelector((state: RootState) => state.history);
  const [dbHistoryData, setDbHistoryData] = React.useState<QuestionInterface[]>([]);
  var [showHistory, setShowHistory] = React.useState<QuestionInterface[]>([]);
  const [isDrawerOpen1, setIsDrawerOpen1] = React.useState(false);
  const [sortOption] = React.useState("newest");
  const [moreButtonRender] = React.useState(true);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [open, setOpen] = React.useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [recenthistory, setrecenthistorybutton] = React.useState(false);
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [showTooltip1, setShowTooltip1] = React.useState(false);
  const folders = ['Folder1', 'Folder2', 'Folder3'];
  const [tooltipContent, setTooltipContent] = React.useState('');
  const [historyData, setHistoryData] = React.useState(null);
  const [chatData, setChatData] = React.useState<any[]>([]);
  const [sessionData, setSessionData] = React.useState<any[]>([]);
  const [tooltipQuestion, setTooltipQuestion] = React.useState('');
  const [tooltipQuestion1, setTooltipQuestion1] = React.useState('');
  const [tooltipAnswer, setTooltipAnswer] = React.useState('');
  const [tooltipAnswer1, setTooltipAnswer1] = React.useState('');
  const [isMouseOver, setIsMouseOver] = React.useState(false);
  const [hover, setHover] = React.useState(false);
  const [tempQuestion, setTempQuestion] = React.useState<QuestionInterface | null>(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState<EventTarget & (HTMLElement | SVGSVGElement) | null>(null);
  const [popoverAnchorEl1, setPopoverAnchorEl1] = React.useState<EventTarget & (HTMLElement | SVGSVGElement) | null>(null);
  const handlePopoverLeave = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    handlePopoverClose();
  };

  let storedSession = sessionStorage.getItem("sessionId");
  //const session = uuidv4();
  let [userInfo, setUserInfo] = React.useState({
    userId: "test@sce.com",
    sessionId: "",
  });

  let userName = "Test Test";

  let email = sessionStorage.getItem("email");
  let name = sessionStorage.getItem("username");

  if (name != null && name != undefined) {
    userName = name;
  }

  if (email !== undefined && email != null) {
    userInfo.userId = email;
  }
  const [editingTodayIndex, setEditingTodayIndex] = React.useState<number | null>(null);
  const [editedTodaySessionName, setEditedTodaySessionName] = React.useState("");
  const [editingPast7DaysIndex, setEditingPast7DaysIndex] = React.useState<number | null>(null);
  const [editingIndex, setEditingIndex] = React.useState<number | null>(null);
  const [editedSessionName, setEditedSessionName] = React.useState("");
  const [editedPast7DaysSessionName, setEditedPast7DaysSessionName] = React.useState("");
  const [hoveredQuestion, setHoveredQuestion] = React.useState<string | null>(null);
  const [selectedSessions, setSessionsForSelectedMonth] = React.useState<any[]>([]);
  const [selectedMonthName, setSelectedMonthName] = React.useState<string | null>(null);


  const handleEditClick1 = (event: React.MouseEvent, index: number, sessionName: string) => {
    setEditingPast7DaysIndex(index);
    setEditedPast7DaysSessionName(sessionName);
    event.stopPropagation();
  };
  const handleEditClick2 = (event: React.MouseEvent, index: number, sessionName: string) => {
    setEditingIndex(index);
    setEditedSessionName(sessionName);
    event.stopPropagation();
  };
  const handleFolderChange = () => {
    setEditingPast7DaysIndex(-1);
    setEditedPast7DaysSessionName('');
    // Additional logic if needed
  };
  const handleDrawerStateChange = (isOpen: boolean) => {
    setIsDrawerOpen1(isOpen);
  };
  const handleCancelClick1 = () => {
    // Reset the editing state
    setEditingPast7DaysIndex(null);
  };
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const inputRefi = React.useRef<HTMLInputElement | null>(null);

  const handleEditClick = (event: React.MouseEvent, sessionIndex: number, sessionName: string) => {
    setEditingTodayIndex(sessionIndex);
    setEditedTodaySessionName(sessionName);
    event.stopPropagation();
  };
  const handleCancelClick = () => {
    // Reset the editing state
    setEditingTodayIndex(null);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
      // Click outside the input field
      setEditingTodayIndex(null);
      // Additional logic if needed
    }
  };


  React.useEffect(() => {
    // Add event listener when editingTodayIndex is not null
    if (editingTodayIndex !== null) {
      document.addEventListener('click', handleOutsideClick);
    }

    // Remove event listener when component unmounts or when editingTodayIndex becomes null
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [editingTodayIndex]);
  React.useEffect(() => {
    // Add event listener when editingTodayIndex is not null
    if (editingIndex !== null) {
      document.addEventListener('click', handleOutsideClick2);
    }

    // Remove event listener when component unmounts or when editingTodayIndex becomes null
    return () => {
      document.removeEventListener('click', handleOutsideClick2);
    };
  }, [editingIndex]);
  const handleOutsideClick2 = (event: MouseEvent) => {
    if (inputRefi.current && !inputRefi.current.contains(event.target as Node)) {
      // Click outside the input field
      setEditingIndex(null);
      // Additional logic if needed
    }
  };
  const handleOutsideClickl = (event: MouseEvent) => {
    if (inputRefi.current && !inputRefi.current.contains(event.target as Node)) {
      // Click outside the input field
      setEditingPast7DaysIndex(null);
      // Additional logic if needed
    }
  };



  interface Session1 {
    session_id: any;
    user_id: any;
    session_name: string;
    session_datetime: any;
  }

  interface Response {
    [key: string]: Session1[];
  }

  React.useEffect(() => {
    // Add event listener when editingPast7DaysIndex is not null
    if (editingPast7DaysIndex !== null) {
      document.addEventListener('click', handleOutsideClickl);
    }

    // Remove event listener when component unmounts or when editingPast7DaysIndex becomes null
    return () => {
      document.removeEventListener('click', handleOutsideClickl);
    };
  }, [editingPast7DaysIndex]);
  const handleIconClick = (question: string, answer: string, event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    setShowTooltip(!showTooltip);
    event.stopPropagation();
    // setTooltipContent(content);
    setTooltipQuestion(question);
    setTooltipAnswer(answer);
    setPopoverAnchorEl(event.currentTarget);
  };
  const handleMouseOut = () => {
    setShowTooltip1(false);
    console.log('handlemouseout');
  };
  const handleQuestionHover = (question: string, answer: string, event: React.MouseEvent<any, MouseEvent>) => {
    handlePopoverClose(); // Close the existing popover
    handleMouseOver(question, answer, event);
    setHoveredQuestion(question); // Open a new popover for the hovered question
  };
  const handleIconClick1 = (question: string, answer: string, event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    setShowTooltip1(!showTooltip);
    event.stopPropagation();
    // setTooltipContent(content);
    setTooltipQuestion1(question);
    setTooltipAnswer1(answer);
    setPopoverAnchorEl1(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);

  };
  const handlePopoverClose1 = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    setPopoverAnchorEl1(null);
  };
  const openPopover = Boolean(popoverAnchorEl);
  const openPopover1 = Boolean(popoverAnchorEl1);

  React.useEffect(() => {
    if (!storedSession) {
      let newSession = uuidv4();
      sessionStorage.setItem("sessionId", newSession);
      userInfo.sessionId = newSession;
    } else {
      userInfo.sessionId = storedSession;
    }

  });


  const [uniqueId, setUniqueId] = React.useState("156");

  const handleDrawerOpen1 = () => {



    setrecenthistorybutton(true);
    setButtonClicked(false);
    setIsDrawerOpen(true);
    setOpen(false);
  };

  const handleDrawerClose1 = () => {
    isDrawerOpen ? setIsDrawerOpen(false) : setIsDrawerOpen(true)
  };


  const handleDrawerOpen = () => {
    setOpen(true);
    setIsDrawerOpen(false);
  };

  const handleDrawerClose = () => {
    open ? setOpen(false) : setOpen(true)
  };
  //const isMobile= window.matchMedia('(max-width: 767px)');
  const env = process.env;

  React.useEffect(() => {
    updateLoadingState("success");
    // getDbHistory().then((data) => {
    //   setDbHistoryData(data);
    //   if (moreButtonRender) {
    //     setShowHistory(data.slice(0, 10))
    //   }
    //   else {
    //     setShowHistory(data)
    //   }
    //   ;
    // });
  }, [moreButtonRender]);
  React.useEffect(() => {
    window.addEventListener("beforeunload", alertUser);

    clearSession(userInfo.userId, userInfo.sessionId)
    .catch(error => {
      console.warn("clearSession failed.Session might not cleared properly");
    });
    sessionStorage.removeItem("sessionId");
    sessionStorage.removeItem("sources");
    gethistory(userInfo.userId, userName)
      .then(serviceResponse => {
          if (serviceResponse.data.status === 'Failed') {
            console.warn("gethistory failed:", serviceResponse.data.message);
            return;
          }
        setHistoryData(serviceResponse.data.seven_days);
        setChatData(serviceResponse.data.today);
      })
      .catch(error => {
        
      })

  }, []);

  React.useEffect(() => {
    window.addEventListener("click", handleClick);

    // cleanup this component
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  const handleClick = (event: { target: any }) => {
    const target = event.target;
    if (
      (target.tagName == "A" || target.tagName == "a") &&
      !event.target.hasAttribute("target")
    ) {
      event.target.setAttribute("target", "_blank");
    }
  };




  // const historyData = response.history;
  // const sortedDates = Object.keys(historyData).sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
  let sessionCounter = 1;
  let allSessions = historyData ? Object.values(historyData).flatMap((sessions) => sessions) : [];

  // const [chatData, setChatData] = React.useState<YourChatDataType | null>(historyData);
  interface Message {
    question: string;
    answer: string;
    flag?: string;
  }

  interface Session {
    session_name: string;
    data: Message[];
  }

  // interface ChatData {
  //   [key: string]: {
  //     [key: string]: Session;
  //   };
  // }


  const alertUser = (e: { preventDefault: () => void; returnValue: string; }) => {
    e.preventDefault();
    e.returnValue = "";
    return true;
  };
  const [expandedAccordion, setExpandedAccordion] = React.useState<string | null>(null);
  const [expandedAccordion1, setExpandedAccordion1] = React.useState<string | null>(null);
  const [expandedAccordion2, setExpandedAccordion2] = React.useState<string | null>(null);
  const [buttonClicked, setButtonClicked] = React.useState(false);
  const [buttonClicked1, setButtonClicked1] = React.useState(false);
  const [search, setSearch] = React.useState(false);
  const [searchResults, setSearchResults] = React.useState<string[]>([]);
  const handleChange1 = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedAccordion1(isExpanded ? panel : null);
    if (isExpanded) {
      const clickedSessionIndex = parseInt(panel.replace("panel", "")) - 1;
      const clickedSessionId = (Object.values(chatData)[clickedSessionIndex] as any)?.session_id;
      const clickedSessionName = (Object.values(chatData)[clickedSessionIndex] as any)?.session_name;

      // handleSaveClick(clickedSessionId,clickedSessionName,event as React.FocusEvent<HTMLInputElement>)
      // handleDeleteClick(clickedSessionId,clickedSessionName,event as React.MouseEvent)
    }
  };
  const [questionText1, setQuestionText1] = React.useState("");
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedAccordion(isExpanded ? panel : null);
    if (isExpanded) {
      const clickedSessionIndex = parseInt(panel.replace("panels", "")) - 1;
      const clickedSessionId = (allSessions[clickedSessionIndex] as any)?.session_id;
      const clickedSessionName = (allSessions[clickedSessionIndex] as any)?.session_name;
      //handleAccordionClick(clickedSessionId,event as React.MouseEvent);
      // handleSaveClick1(clickedSessionId,clickedSessionName,event as React.FocusEvent<HTMLInputElement>)
      // handleDeleteClick1(clickedSessionId,clickedSessionName, event as React.MouseEvent)
    }
  };
  const handleChange2 = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedAccordion2(isExpanded ? panel : null);
    if (isExpanded) {
      const clickedSessionIndex = parseInt(panel.replace("panelse", "")) - 1;
      const clickedSessionId = (allSessions[clickedSessionIndex] as any)?.session_id;
      const clickedSessionName = (allSessions[clickedSessionIndex] as any)?.session_name;
      //handleAccordionClick(clickedSessionId,event as React.MouseEvent);
      // handleSaveClick1(clickedSessionId,clickedSessionName,event as React.FocusEvent<HTMLInputElement>)
      // handleDeleteClick1(clickedSessionId,clickedSessionName, event as React.MouseEvent)
    }
  };
  const [selectedMonth, setSelectedMonth] = React.useState<any>(null);

  type Session2 = {
    session_name: string;
    // Add other session properties as needed
  };

  type SelectedMonth = Record<string, Session2[]>;



  const handleMonthClick = (month: string) => {
    if (selectedMonthName === month) {
      // If the clicked month is already selected, reset the selected month
      setSelectedMonthName('');
    } else {
      // If the clicked month is not selected, set it as the selected month
      setSelectedMonthName(month);
    }
  };

  const handleSearch = async (query: string) => {
    updateLoadingState("loading");

    searchhistory(userInfo.userId, userName, query)
      .then(serviceResponse => {
        setSearchResults(serviceResponse.data.search_result);
        setSearch(true);
      })
      .catch(error => {
      })

      .finally(() => {
        updateLoadingState("success");
      })

    // const mockSearchResults = ['Result 1','Result 1' ,'Result 2', 'Result 3'];
    // const filteredResults = mockSearchResults.filter(result =>
    //   result.toLowerCase() === searchQuery.toLowerCase()
    // );

    // setSearchResults(filteredResults.length > 0 ? filteredResults : ['No results found']);
    // setSearchResults(mockSearchResults);
  };

  const emptyarray: string[] = [];
  const handleAccordionClick = (session_id: any, event: React.MouseEvent) => {
    event.stopPropagation();
    setSessionData(emptyarray);
    updateLoadingState("loading");
    getsession(userInfo.userId, session_id, userName)
      .then(response => {
        const sessionResponse = response;
        setSessionData(sessionResponse.data.session_data);
      })
      .catch(error => {
      })
      .finally(() => {
        updateLoadingState("success");
      });

  };
  const handleAccordionClick2 = (session_id: any, event: React.MouseEvent) => {
    event.stopPropagation();
    setSessionData(emptyarray);
    updateLoadingState("loading");
    getsession(userInfo.userId, session_id, userName)
      .then(response => {
        const sessionResponse = response;
        setSessionData(sessionResponse.data.session_data);
      })
      .catch(error => {
      })
      .finally(() => {
        updateLoadingState("success");
      });

  };

  const handleButtonClick = (e: React.MouseEvent) => {
    console.log(e);



    // // Set loading state to true when the gethistory call starts
    updateLoadingState("loading");
    setSearchQuery('');
    setSearch(false);
    setSearchResults(emptyarray);
    getchathistory(userInfo.userId, userName)
      .then(serviceResponse => {
        if (serviceResponse.data.status === 'Failed') {
          console.warn("getchathistory failed:", serviceResponse.data.message);
          setSelectedMonth({});
          return;
        }
        setSelectedMonth(serviceResponse.data);
      })
      .catch(error => {
      })
      .finally(() => {
        // Reset the loading state when the gethistory call is complete
        updateLoadingState("success");

        setButtonClicked(true);
        setButtonClicked1(false);
        setrecenthistorybutton(false);
       
      });
  };
  const handleButtonClick1 = (e: React.MouseEvent) => {
    console.log(e);



    // // Set loading state to true when the gethistory call starts
    updateLoadingState("loading");
    setSearchQuery('');
    setSearch(false);
    setSearchResults(emptyarray);
    gethistory(userInfo.userId, userName)
      .then(serviceResponse => {
        if (serviceResponse.data.status === 'Failed') {
          console.warn("gethistory failed:", serviceResponse.data.message);
          return;
        }
        setHistoryData(serviceResponse.data.seven_days);
        setChatData(serviceResponse.data.today);

      })
      .catch(error => {
      })
      .finally(() => {
       
        updateLoadingState("success");

        setButtonClicked1(true);
        setButtonClicked(false);
        
      });
  };
  const handleSaveClick = (session_id: any, event: React.FocusEvent<HTMLInputElement>) => {
    event.stopPropagation();

 
    getrename(userInfo.userId, session_id, editedTodaySessionName, "rename");

   
    updateLoadingState("loading");

    gethistory(userInfo.userId, userName)
      .then(serviceResponse => {
        if (serviceResponse.data.status === 'Failed') {
          console.warn("gethistory failed:", serviceResponse.data.message);
          return;
        }
        setHistoryData(serviceResponse.data.seven_days);
        setChatData(serviceResponse.data.today);
      })
      .catch(error => {
      })
      .finally(() => {
       
        updateLoadingState("success");

    
        setEditingTodayIndex(-1);
        setEditedTodaySessionName("");
      });
  };
  const handleSaveClick1 = (session_id: any, event: React.FocusEvent<HTMLInputElement>) => {
    event.stopPropagation();

   
    getrename(userInfo.userId, session_id, editedPast7DaysSessionName, "rename");
    updateLoadingState("loading");

    gethistory(userInfo.userId, userName)
      .then(serviceResponse => {
        if (serviceResponse.data.status === 'Failed') {
          console.warn("gethistory failed:", serviceResponse.data.message);
          return;
        }
        setHistoryData(serviceResponse.data.seven_days);
        setChatData(serviceResponse.data.today);
      })
      .catch(error => {
      })
      .finally(() => {
       
        updateLoadingState("success");
      
        setEditingPast7DaysIndex(-1);
        setEditedPast7DaysSessionName("");
      });
  };
  const handleSaveClick2 = (session_id: any, event: React.FocusEvent<HTMLInputElement>) => {
    event.stopPropagation();

    
    getrename(userInfo.userId, session_id, editedSessionName, "rename");

   
    updateLoadingState("loading");

    getchathistory(userInfo.userId, userName)
      .then(serviceResponse => {
        if (serviceResponse.data.status === 'Failed') {
          console.warn("getchathistory failed:", serviceResponse.data.message);
          setSelectedMonth({});
          return;
        }
        setSelectedMonth(serviceResponse.data);
      })
      .catch(error => {
      })
      .finally(() => {
      
        updateLoadingState("success");

       
        setEditingIndex(-1);
        setEditedSessionName("");
      });
  };
  const handleDeleteClick = (session_id: any, session_name: any, event: React.MouseEvent) => {
    event.stopPropagation();
    getrename(userInfo.userId, session_id, session_name, "delete")
    updateLoadingState("loading");
    gethistory(userInfo.userId, userName)
      .then(serviceResponse => {
        if (serviceResponse.data.status === 'Failed') {
          console.warn("gethistory failed:", serviceResponse.data.message);
          return;
        }
        setHistoryData(serviceResponse.data.seven_days);
        setChatData(serviceResponse.data.today);
      })
      .catch(error => {
      })
      .finally(() => {
        
        updateLoadingState("success");
      });
  };
  const handleDeleteClick2 = (session_id: any, session_name: any, index: number, month: string, event: React.MouseEvent) => {
    event.stopPropagation();
    getrename(userInfo.userId, session_id, session_name, "delete")
      .then(() => {
        updateLoadingState("loading");
        return getchathistory(userInfo.userId, userName);
      })
      .then(serviceResponse => {
        setSelectedMonth(serviceResponse.data);

        setExpandedAccordion2((prevExpanded) => {
          const updatedExpanded = Object.assign({}, prevExpanded);
          delete (updatedExpanded as any)[`panelse-${month}-${index + 1}`];
          return updatedExpanded;
        });
      })
      .catch(error => {
      })
      .finally(() => {
        updateLoadingState("success");
      });
  };


  const handleDeleteClick1 = (session_id: any, session_name: any, index: number, event: React.MouseEvent) => {
    event.stopPropagation();
    getrename(userInfo.userId, session_id, session_name, "delete")
    updateLoadingState("loading");
    gethistory(userInfo.userId, userName)
      .then(serviceResponse => {
        if (serviceResponse.data.status === 'Failed') {
          console.warn("gethistory failed:", serviceResponse.data.message);
          return;
        }
        setHistoryData(serviceResponse.data.seven_days);
        setChatData(serviceResponse.data.today);
        setExpandedAccordion((prevExpanded) => {
          const updatedExpanded = Object.assign({}, prevExpanded);
          delete (updatedExpanded as any)[`panels${index + 1}`];
          return updatedExpanded;
        });
      })
      .catch(error => {
      })
      .finally(() => {
        
        updateLoadingState("success");
      });
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {

    if (reason === "clickway") {
      return;
    }

    updateOpenSnackbar(false);
  };

  const showSnackbar = (message: string, type: string) => {
    updateSnackbarMessage(message);
    updateSnackbarType(type);
    updateOpenSnackbar(true);
    
  };
  const isDisabled = questionText.trim().length === 0 || /^[^\w\s]/.test(questionText) ||  /^\s/.test(questionText);
  const onQuestionValueChange = (event: React.BaseSyntheticEvent) => {
    updateQuestionText(event.target.value);
  };
  const sendQuestionNew = (event: React.BaseSyntheticEvent) => {

    const questions = event.currentTarget.dataset.fulltext;
    sessionStorage.setItem("questionSelected", questions);
    updateQuestionText(questions);
    sendQuestion(event);

  };


  const bringNewQuestionToFocus = (questionId: number | string) => {
    setTimeout(() => {
      const element: HTMLElement | null = document.getElementById(
        `question-${questionId}`
      );

      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }, 1000 / 60);
  };

  const setQuestionFromHistory = (question: QuestionInterface) => {
    if (questionReducer.questions.findIndex((formQuestion: QuestionInterface) => formQuestion.id === question.id) === -1) {
      dispatch(addNewQuestion([question]));
    }
    setTimeout(() => {
      // updateQuestionText(question.question);
      bringNewQuestionToFocus(question.id);
    }, 1000 / 60);
  };

  const sendQuestion = (event: React.BaseSyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
  
    updateLoadingState("loading");
    const startTime = performance.now();
    let questionSelected = sessionStorage.getItem("questionSelected");
    getAnswerService(
      questionText
        ? questionText
        : questionSelected
          ? questionSelected
          : event.target.value,
      userInfo.userId,
      userInfo.sessionId,
      parentquestionid ? parentquestionid : event.target.id,
      userName
    )
      .then((res: any) => {
        updateRequestSent(false);
        if (res.data) {
          const endTime = performance.now();
          const timeStamp: number = new Date().getTime();
          const idTimestamp: number = parseInt(
            new Date()
              .toISOString()
              .replace(/[-:.TZ]/g, "")
              .slice(0, 14)
          );
          sessionStorage.removeItem("questionSelected");
          const tempQuestion: QuestionInterface = {
            ...res.data,
            question: questionText
              ? questionText
              : questionSelected
                ? questionSelected
                : event.target.value,
            id: idTimestamp,
            dateCreated: timeStamp,
            timeTakenToRespond: `${(endTime - startTime) / 1000}`,
            rating: 0,
            user: {
              userId: userInfo.userId,
              sessionId: userInfo.sessionId,
              uniqueId: uniqueId,
            },
          };
          setTempQuestion(tempQuestion);
          dispatch(addNewQuestion([JSON.parse(JSON.stringify(tempQuestion))]));
          // tempQuestion.history = true;
          dispatch(addNewQuestionToHistory([tempQuestion]));
          dbHistoryData.unshift(tempQuestion);
          showHistory.unshift(tempQuestion);
          if (moreButtonRender) {
            setShowHistory(showHistory.slice(0, 10));
          }
          updateLoadingState("success");
          bringNewQuestionToFocus(tempQuestion.id);
          updateQuestionText("");
          showSnackbar("Your results are ready.", "success");
        }
      })
      .catch((err: any) => {
        updateRequestSent(false);

        updateLoadingState("success");
        showSnackbar("Unable to retrieve results. Please try again", "error");
      });
  };
  const handleKeyBoardEvent = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.stopPropagation();
      event.preventDefault();
      const isWhitespaceOnly = /^\s*$/.test(questionText);
      const startsWithDisabledChar = /^[^\w\s]/.test(questionText.trim());
    
      if (startsWithDisabledChar || isWhitespaceOnly) {
        updateQuestionText("");
        showSnackbar("Please type a valid question", "error");
       
      } else if (!requestSent && questionText.trim()) {
        sendQuestion(event);
        updateRequestSent(true);
      }
      
      return;
    }
  
    
    if (questionText.trim().length === 0 && /^[ ,.'":;!?]/.test(event.key)) {
      updateQuestionText("");
      showSnackbar("Please type a valid question", "error");
    
    }
  };
  
  const handleKeyboardEvent1 = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      event.preventDefault();
      if (searchQuery.trim()) {
        handleSearch(searchQuery);
      }

    }
  };

  // const newConversation = (event: React.SyntheticEvent) => {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   updateLoadingState("loading");
  //   newConversationInitiaited()
  //     .then((res: any) => {
  //       dispatch(markEverythingAsHistory());
  //       dispatch(clearState());
  //       updateSnackbarMessage("New Conversation Initiated");
  //       updateSnackbarType("success");
  //       updateLoadingState("success");
  //       updateOpenSnackbar(true);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       updateSnackbarMessage("Unable to initiate new conversation");
  //       updateSnackbarType("error");
  //       updateLoadingState("success");
  //       updateOpenSnackbar(true);
  //     });
  // };

  const updateQuestionRating = async (question: QuestionInterface, rating: QuestionRatingType) => {
    updateLoadingState("loading");
    try {
      const resp = await rateQuestionService(question, rating);
      const rateresponse: RateResponse = resp.data;

      dispatch(
        updaterateresponse(
          { question, rateresponse }
        )
      );

      dispatch(
        updateRating(
          { question, rating }
        )
      );
      updateLoadingState("success");
      showSnackbar(rating === 0 ? "Question rating removed." : "Question rated successfully.", "success");
    } catch (error) {
      updateLoadingState("success");
      showSnackbar("Unable to rate question", "error");
    }
  }
  const handleRelatedTopicClick = async (question: QuestionInterface) => {
    const response = await axios.post(
      `${env.REACT_APP_API_URL}/get_topic`,
      { "topic": question.default_topic },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const dataArray = response.data.Result_list;

    var skipIdArray = []
    for (let i = 0; i < dataArray.length; i++) {
      const data = dataArray[i];

      if (document.getElementById(`question-${data.RowKey}`)) {
        skipIdArray.push(data.RowKey)
        continue;
      }

      const tempQuestion: QuestionInterface = {
        ...data,
        question: data.Question,
        response: data.answer,
        id: data.RowKey,
        dateCreated: data.RowKey,
        rating: 0,
        timeTakenToRespond: data.responseTime,
        questionSource: "Related Topic"
      };

      dispatch(addNewQuestion([JSON.parse(JSON.stringify(tempQuestion))]));
      if (i === 0) {
        bringNewQuestionToFocus(tempQuestion.id);
      }
    }
    if (skipIdArray.length === dataArray.length && skipIdArray.length !== 0) {
      bringNewQuestionToFocus(Math.min(...skipIdArray));
    }
  };

  showHistory = showHistory.sort((a, b) => {
    if (sortOption === "newest") {
      return b.id - a.id;
    } else if (sortOption === "oldest") {
      return a.id - b.id;
    } else {
      return 0;
    }
  });


  // showHistory = showHistory.filter((data) =>
  //   data.question.toLowerCase().includes(searchQuery.toLowerCase())
  // );

  const relatedTopics: QuestionInterface[] = [];
  for (const item of historyReducer.questions) {
    const topicExists = relatedTopics.some(topicItem => topicItem.default_topic === item.default_topic);
    if (!topicExists) {
      relatedTopics.push(item);
    }
  }
  interface ChatData {
    [sessionId: string]: {
      session_name: string;
      data: {
        question: string;
        answer: string;
        flag: string;
      }[];
    };
  }

  const handleMouseOver = (
    question: string,
    answer: string,
    event: React.MouseEvent<any, MouseEvent>
  ) => {
    setShowTooltip(!showTooltip);
    // event.stopPropagation();
    // setTooltipContent(content);
    setTooltipQuestion(question);
    setTooltipAnswer(answer);
    setPopoverAnchorEl(event.currentTarget);
  };
  const handleMouseLeave = () => {
    setShowTooltip(false);
    setPopoverAnchorEl(null);
  };

  const handleMouseLeave1 = (event: React.MouseEvent) => {
    setHoveredQuestion(null); // Clear the hovered question when the mouse leaves
    // ... other logic if needed ...
  };
  const handleMouseOver1 = (
    question: string,
    answer: string,
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    setIsMouseOver(true);
    setShowTooltip1(!showTooltip);
    event.stopPropagation();
    // setTooltipContent(content);
    setTooltipQuestion1(question);
    setTooltipAnswer1(answer);
    setPopoverAnchorEl1(event.currentTarget);


  };



  const renderMiniDrawer = () => {

    // let isMobileDevice = RegExp.test(details); 
    return (

      <Box sx={{
        display: 'flex', marginLeft: '-9px'
      }}>
        <CssBaseline />
        {/* <AppBar position="fixed" open={open}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                        marginRight: 5,
                        ...(open && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
            </Toolbar>
        </AppBar> */}
        <Drawer variant="permanent" open={open}>
          <List sx={{ marginTop: "75px", height: "90%", maxHeight: "90%" }}>
            <ListItem key={"Advise AI"} disablePadding sx={{ display: 'block', height: open ? "7vh" : "auto", maxHeight: "35vh" }} onClick={open ? handleDrawerClose : handleDrawerOpen}>
              <Tooltip title="Advise AI" arrow>
                <ListItemButton
                  sx={{
                    minHeight: "30px",
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    color: "#FFFFFF",
                    font: "normal normal normal 24px/32px Segoe UI"
                    // border:"2px solid rgb(88, 137, 53)",
                    // borderRadius:"5px",
                    // bgcolor:"rgba(88, 137, 53, 0.5)"
                  }}
                  className={open ? 'mx-1 py-0 my-0' : ''}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                      color: '#FFFFFF',
                    }}
                  >


                    {/* {isMobileDevice?<ZoomIn />:null} */}
                    {open ? <ChevronLeft /> : null}
                  </ListItemIcon>
                  <ListItemText primary={"Advise AI"} sx={{ opacity: open ? 1 : 0, }} />
                  {open ? null : <ChevronRight />}
                </ListItemButton>
              </Tooltip>
              {open ? (
                // <div style={{
                //   maxWidth: "100%", display: "block", 
                //   height: "calc(100% - 30px)", 
                //   overflowY: "auto"
                // }}>
                <List sx={{ paddingLeft: 1, display: "grid", flexWrap: "wrap" }}>
                  {/* {
                    relatedTopics.slice(-10).reverse().map((data: QuestionInterface, index: number) => (
                      <Button
                        variant="text"
                        sx={{
                          textAlign: "left",
                          textTransform: "none",
                          width: "100%",
                        }}
                        title={data.question}
                        onClick={() => handleRelatedTopicClick(data)}
                        key={`related-button-${index}`}
                      >
                        <div style={{ width: "100%" }}>
                          {data.default_topic}
                        </div>
                      </Button>
                    ))
                  } */}
                  {/* {(relatedTopics.length === 0) && (
                      <Typography variant="body2" sx={{ textAlign: "center", mt: 7 }}>
                        No Questions Asked Yet!
                      </Typography>
                    )} */}
                </List>
                //</div>
              ) : null}
            </ListItem>

            <ListItem key={"Saved Searches"} disablePadding sx={{ display: 'none', height: open ? "7vh" : "auto", maxHeight: "47vh" }}>
              <Link underline="none">
                {/* <a href="https.sce.com" target="_blank" rel="noopener noreferrer"> */}
                <ListItemButton
                  sx={{
                    // color:"black",
                    minHeight: 30,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    font: "normal normal normal 18px/24px Segoe UI",
                    pointerEvents: open ? "none" : "auto",

                    // border:"2px solid rgb(254, 209, 65)",
                    // borderRadius:"5px",
                    // bgcolor:"rgba(254, 209, 65, 0.5)"
                    color: "#FFFFFF",
                    background: "#00664FCC 0% 0% no-repeat padding-box",
                    borderRadius: "6px",
                    opacity: "1",
                    width: open ? "290px" : "0px",
                    marginLeft: open ? "0px" : "10px",
                    height: "0px",
                    top: open ? "0px" : "8px",
                  }}
                  className={open ? 'mx-1 py-0 my-0' : ''}

                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                      borderRadius: "6px",

                    }}
                  >
                    <ZoomIn />

                  </ListItemIcon>
                  <ListItemText primary={"Saved Searches"} sx={{ opacity: open ? 1 : 0, }} />
                  {open ? <ChevronRight /> : null}
                </ListItemButton>
              </Link>
            </ListItem>


            <ListItem key={"History"} disablePadding sx={{ display: 'block', height: open ? "7vh" : "auto", maxHeight: "47vh" }}>
              <Tooltip title="Chat History" arrow>
                <ListItemButton

                  onClick={isDrawerOpen ? handleDrawerClose1 : handleDrawerOpen1}
                  sx={{
                    '&:hover': {
                      color: isDrawerOpen && open ? "#FFFFFF" : null, // Set the desired color on hover
                      background: isDrawerOpen && open ? "#00664FCC 0% 0% no-repeat padding-box" : null,
                      borderRadius: isDrawerOpen && open ? "6px" : null,
                      // other hover styles...
                    },
                    // minHeight: 30,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    color: isDrawerOpen && open ? "#898989" : "#FFFFFF",
                    top: open ? "0px" : "15px",
                    background: isDrawerOpen && open ? "#F0F0F0 0% 0% no-repeat padding-box" : "#247B68",
                    borderRadius: isDrawerOpen && open ? "6px" : null,

                  }}
                  className={open ? 'mx-1 py-0 my-0' : ''}

                >
                  <ListItemIcon
                    sx={{

                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                      background: isDrawerOpen ? "#F0F0F0 0% 0% no-repeat padding-box" : "#247B68",
                      borderRadius: isDrawerOpen && !open ? "6px" : null,
                      color: isDrawerOpen ? "#898989" : "#FFFFFF",
                      paddingTop: isDrawerOpen && !open ? '4px' : null,
                      paddingBottom: isDrawerOpen && !open ? '4px' : null,
                      marginBottom: isDrawerOpen && !open ? '13px' : null,
                      width: isDrawerOpen && !open ? '32px' : null
                    }}
                  >
                    <History />
                  </ListItemIcon>
                  <ListItemText primary={"Chat History"} sx={{ opacity: open ? 1 : 0 }} />
                  {open ? <ChevronRight /> : null}
                </ListItemButton>
              </Tooltip>
              {open ? (
                <List sx={{ paddingX: 1, display: "grid", }}>

                  {/* {
                    showHistory.map((data, index) => (
                      <Button
                        variant="text"
                        sx={{
                          textAlign: "left",
                          textTransform: "none",
                          width: "100%",
                          marginTop: 1,
                          fontWeight: ({
                            ...(data.isSummary ? { fontWeight: "bold" } : {})
                          })
                        }}
                        onClick={() => setQuestionFromHistory(data)}
                        key={`related-button-${index}`}
                      >
                        <div style={{ width: "100%" }}>
                          {data.question.length > 30 ? data.question.slice(0, 30) + "..." : data.question}
                        </div>
                      </Button>
                    ))
                  } */}
                  {/* {dbHistoryData.length !== showHistory.length && moreButtonRender && (
                      <Button
                        variant="outlined"
                        sx={{
                          width: "100%",
                          marginTop: 2,

                        }}
                        onClick={() => {
                          setMoreButtonRender(false);
                          setShowHistory(dbHistoryData);
                        }}
                      >
                        Load More...
                      </Button>
                    )} */}


                </List>
                // </div>
              ) : null}
            </ListItem>


            <ListItem key={"Trending Content"} disablePadding sx={{ display: 'none', height: open ? "7vh" : "auto", maxHeight: "47vh" }}>
              <Link underline="none">
                <ListItemButton
                  sx={{
                    // color:"black",
                    minHeight: 30,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    color: "#898989",
                    top: open ? "0px" : "10px",
                    // border:"2px solid rgb(254, 209, 65)",
                    // borderRadius:"5px",
                    // bgcolor:"rgba(254, 209, 65, 0.5)"
                  }}
                  className={open ? 'mx-1 py-0 my-0' : ''}

                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <TrendingUp />
                  </ListItemIcon>
                  <ListItemText primary={"Trending Content"} sx={{ opacity: open ? 1 : 0 }} />
                  {open ? <ChevronRight /> : null}
                </ListItemButton>
              </Link>
            </ListItem>


            <ListItem key={"City/District"} disablePadding sx={{ display: 'block', height: open ? "7vh" : "auto", maxHeight: "47vh" }}>
              <Link href="https://edisonintl.sharepoint.com/teams/FETCH/SitePages/City-District-Information.aspx" underline="none" target="_blank" rel="noopener noreferrer">
                <Tooltip title="City/District" arrow>
                  <ListItemButton
                    sx={{
                      // color:"black",
                      minHeight: 30,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      color: "#FFFFFF",
                      top: open ? "0px" : "10px",
                      // border:"2px solid rgb(254, 209, 65)",
                      // borderRadius:"5px",
                      // bgcolor:"rgba(254, 209, 65, 0.5)"
                    }}
                    className={open ? 'mx-1 py-0 my-0' : ''}


                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                        color: '#FFFFFF',
                      }}
                    >
                      <Apartment />
                    </ListItemIcon>
                    <ListItemText primary={"City/District"} sx={{ opacity: open ? 1 : 0 }} />

                  </ListItemButton>
                </Tooltip>
              </Link>
            </ListItem>

            <ListItem key={"Viewers and Calculators"} disablePadding sx={{ display: 'block', height: open ? "7vh" : "auto", maxHeight: "47vh" }}>
              <Link href="https://edisonintl.sharepoint.com/teams/FETCH/SitePages/AdvancedToolsViewers.aspx" underline="none" target="_blank" rel="noopener noreferrer">
                <Tooltip title="Viewers and Calculators" arrow>
                  <ListItemButton
                    sx={{
                      // color:"black",
                      minHeight: 30,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      color: "#FFFFFF",
                      top: open ? "0px" : "10px",
                      // border:"2px solid rgb(254, 209, 65)",
                      // borderRadius:"5px",
                      // bgcolor:"rgba(254, 209, 65, 0.5)"
                    }}
                    className={open ? 'mx-1 py-0 my-0' : ''}

                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                        color: '#FFFFFF',
                      }}
                    >
                      <Calculate />
                    </ListItemIcon>
                    <ListItemText primary={"Viewers and Calculators"} sx={{ opacity: open ? 1 : 0 }} />

                  </ListItemButton>
                </Tooltip>
              </Link>
            </ListItem>

            <ListItem key={"Forms"} disablePadding sx={{ display: 'block', height: open ? "7vh" : "auto", maxHeight: "47vh" }}>
              <Link href="https://edisonintl.sharepoint.com/teams/FETCH/SitePages/FETCH%20Online%20Forms.aspx" underline="none" target="_blank" rel="noopener noreferrer">
                <Tooltip title="Forms" arrow>
                  <ListItemButton
                    sx={{
                      // color:"black",
                      minHeight: 30,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      color: "#FFFFFF",
                      top: open ? "0px" : "10px",
                      // border:"2px solid rgb(254, 209, 65)",
                      // borderRadius:"5px",
                      // bgcolor:"rgba(254, 209, 65, 0.5)"
                    }}
                    className={open ? 'mx-1 py-0 my-0' : ''}

                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                        color: '#FFFFFF',
                      }}
                    >
                      <TextSnippet />
                    </ListItemIcon>
                    <ListItemText primary={"Forms"} sx={{ opacity: open ? 1 : 0 }} />

                  </ListItemButton>
                </Tooltip>
              </Link>
            </ListItem>

            <ListItem key={"Directory & Contacts"} disablePadding sx={{ display: 'block', height: open ? "7vh" : "auto", maxHeight: "47vh" }}>
              <Link href="https://edisonintl.sharepoint.com/teams/FETCH/SitePages/Directory-Contacts.aspx" underline="none" target="_blank" rel="noopener noreferrer">
                <Tooltip title="Directory & Contacts" arrow>
                  <ListItemButton
                    sx={{
                      // color:"black",
                      minHeight: 30,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      color: "#FFFFFF",
                      top: open ? "0px" : "10px",
                      // border:"2px solid rgb(254, 209, 65)",
                      // borderRadius:"5px",
                      // bgcolor:"rgba(254, 209, 65, 0.5)"
                    }}
                    className={open ? 'mx-1 py-0 my-0' : ''}

                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                        color: '#FFFFFF',
                      }}
                    >
                      <Contacts />
                    </ListItemIcon>
                    <ListItemText primary={"Directory & Contacts"} sx={{ opacity: open ? 1 : 0 }} />

                  </ListItemButton>
                </Tooltip>
              </Link>
            </ListItem>

            <ListItem key={"News Post"} disablePadding sx={{ display: 'block', height: open ? "7vh" : "auto", maxHeight: "47vh" }}>
              <Link href="https://edisonintl.sharepoint.com/teams/CSOD%20Knowledge%20Management/CSODNewsPost/SitePages/CSOD-Communications-News-Posts-Archive.aspx" underline="none" target="_blank" rel="noopener noreferrer">
                <Tooltip title="News Post" arrow>
                  <ListItemButton
                    sx={{
                      // color:"black",
                      minHeight: 30,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      color: "#FFFFFF",
                      top: open ? "0px" : "10px",
                      // border:"2px solid rgb(254, 209, 65)",
                      // borderRadius:"5px",
                      // bgcolor:"rgba(254, 209, 65, 0.5)"
                    }}
                    className={open ? 'mx-1 py-0 my-0' : ''}

                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                        color: '#FFFFFF',
                      }}
                    >
                      <Newspaper />
                    </ListItemIcon>
                    <ListItemText primary={"News Post"} sx={{ opacity: open ? 1 : 0 }} />

                  </ListItemButton>
                </Tooltip>
              </Link>
            </ListItem>

            <ListItem key={"Training Resources"} disablePadding sx={{ display: 'block', height: open ? "7vh" : "auto", maxHeight: "47vh" }}>
              <Link href="https://edisonintl.sharepoint.com/teams/FETCH/SitePages/Training-Resources.aspx" underline="none" target="_blank" rel="noopener noreferrer">
                <Tooltip title="Training Resources" arrow>
                  <ListItemButton
                    sx={{
                      // color:"black",
                      minHeight: 30,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      color: "#FFFFFF",
                      top: open ? "0px" : "10px",
                      // border:"2px solid rgb(254, 209, 65)",
                      // borderRadius:"5px",
                      // bgcolor:"rgba(254, 209, 65, 0.5)"
                    }}
                    className={open ? 'mx-1 py-0 my-0' : ''}

                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                        color: '#FFFFFF',
                      }}
                    >
                      <ModelTrainingIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Training Resources"} sx={{ opacity: open ? 1 : 0 }} />


                  </ListItemButton>
                </Tooltip>
              </Link>
            </ListItem>
            
            <ListItem key={"Explore Resources"} disablePadding sx={{ display: 'block', height: open ? "7vh" : "auto", maxHeight: "47vh" }}>
              <Link href="https://edisonintl.sharepoint.com/teams/CSOD%20Knowledge%20Management/CPSSI/SSOOT/Customer%20Contact%20Center/Forms/AllItems.aspx?RootFolder=%2Fteams%2FCSOD%20Knowledge%20Management%2FCPSSI%2FSSOOT%2FCustomer%20Contact%20Center%2FTraining%20Material%2FAdvise%20AI&FolderCTID=0x012000186D76B96472A846978ADAB88E14B3EB&View=%7B8D6CE435-FD4A-41C4-A95E-125FA102C8CA%7D" underline="none" target="_blank" rel="noopener noreferrer">
                <Tooltip title="Explore Resources" arrow>
                  <ListItemButton
                    sx={{
                      // color:"black",
                      minHeight: 30,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      color: "#FFFFFF",
                      top: open ? "0px" : "10px",
                      // border:"2px solid rgb(254, 209, 65)",
                      // borderRadius:"5px",
                      // bgcolor:"rgba(254, 209, 65, 0.5)"
                    }}
                    className={open ? 'mx-1 py-0 my-0' : ''}

                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                        color: '#FFFFFF',
                      }}
                    >
                      <LibraryBooksIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Explore Resources"} sx={{ opacity: open ? 1 : 0 }} />


                  </ListItemButton>
                </Tooltip>
              </Link>
            </ListItem>
            <ListItem key={"Dashboard"} disablePadding sx={{ display: 'block', height: open ? "7vh" : "auto", maxHeight: "47vh" }}>
              <Link href="/dashboard" underline="none" target="_blank" rel="noopener noreferrer">
                <Tooltip title="Dashboard" arrow>
                  <ListItemButton
                    sx={{
                      // color:"black",
                      minHeight: 30,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      color: "#FFFFFF",
                      top: open ? "0px" : "10px",
                      // border:"2px solid rgb(254, 209, 65)",
                      // borderRadius:"5px",
                      // bgcolor:"rgba(254, 209, 65, 0.5)"
                    }}
                    className={open ? 'mx-1 py-0 my-0' : ''}

                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                        color: '#FFFFFF',
                      }}
                    >
                      <SpaceDashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Dashboard"} sx={{ opacity: open ? 1 : 0 }} />


                  </ListItemButton>
                </Tooltip>
              </Link>
            </ListItem>

          </List>
          {/* <IconButton onClick={handleDrawerClose} sx={{
            "width": "40px",
            marginLeft: "auto",
            marginRight: "auto",
            bgcolor: "black",
            color: "white",
            "&:hover": {
              bgcolor: "black",
              color: "white",
            }
          }}>
            {open ? < ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton> */}

        </Drawer>
        <Drawer_new
          variant="permanent"
          anchor="top"
          open={isDrawerOpen}
          onClose={handleDrawerClose1}
          sx={{ overflowY: 'auto' }}
        >
          <div>
            <Typography
              variant="h6"
              noWrap
              style={{
                position: 'relative',
                top: '79px',
                overflow: 'unset',
                cursor: 'pointer',
                lineHeight: '2.2',
                fontWeight: 'bold',
                transition: 'background 0.3s',
              }}
              onClick={handleDrawerClose1}
            >
              <ListItemIcon style={{ color: 'black', position: 'relative', top: '4px', border: '10px solid transparent', marginRight: '-21px', marginLeft: '-12px', fontWeight: 'bold' }}>
                <ChevronLeftIcon onClick={handleDrawerClose1} />
              </ListItemIcon>
              Chat History
            </Typography>
            <div style={{ position: 'relative', top: '80px', backgroundColor: '#00664FCC', height: '3px' }}/>
            <div style={{ marginTop: '87px' }}>
              <Button
                id="recent"
                variant="contained"
                style={{
                  borderRadius: '6px',
                  color: buttonClicked1 || recenthistory ? '#FFFFFF' : '#898989',
                  background: buttonClicked1 || recenthistory ? '#247B68' : '#F0F0F0',
                  width: '48%',

                  height: '36px',
                  marginBottom: '16px',
                  top: '8px',
                }}
                onClick={(e) => handleButtonClick1(e)}
              >
                Recent History
              </Button>

              <Button
                variant="contained"
                id="chat"
                style={{
                  borderRadius: '6px',
                  // color: '#FFFFFF',
                  width: '47%',
                  // background: '#00664F',
                  color: buttonClicked ? '#FFFFFF' : '#898989',
                  background: buttonClicked ? '#247B68' : '#F0F0F0',
                  marginLeft: '4px',
                  height: '36px'
                }}

                onClick={(e) => handleButtonClick(e)}
              >
                Chat Archives
              </Button>
              {buttonClicked ? (
                //** */ Render content for chat archives**
                <div>

                  <Paper
                    component="form"
                    sx={{
                      width:280,
                      height: 32,
                      backgroundColor: '#F0F0F0',
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: '6px',
                      position: 'relative',
                      top: '5px',
                      marginBottom: '17px',
                    }}
                  >
                    <InputBase
                      placeholder="Search"
                      sx={{
                        ml: 1,
                        flex: 1,
                        height: '100%',
                        padding: '0 8px',
                        fontSize: '14px',
                      }}
                      inputProps={{ 'aria-label': 'search' }}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      onKeyDown={handleKeyboardEvent1}
                    />
                    <IconButton
                      type="button"
                      sx={{
                        p: '8px',
                        borderRadius: 0,
                        '&:hover': {
                          backgroundColor: '#D0D0D0',
                        },
                      }}
                      aria-label="search"
                      onClick={() => handleSearch(searchQuery)}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                  {search && (
                    <>
                      {searchResults.length > 0 ? (
                        <div
                          style={{
                            width: 280,
                            maxHeight: 200,
                            overflow: 'auto',
                            border: '1px solid #CCCCCC',
                            borderRadius: '6px',
                            backgroundColor: '#FFFFFF',
                            position: 'relative',
                            top: '-4px',
                            overflowY: 'auto',
                            overflowX: 'hidden'
                          }}
                        >
                          {searchResults.map((result: any, index) => (
                            <div key={index} >
                              < Typography style={{ padding: '8px', borderBottom: '1px solid #CCCCCC', fontSize: '12px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <span style={{ cursor: 'pointer', fontSize: '11px' }} onMouseOver={(event) =>
                                  handleQuestionHover(result.question, result.answer, event)
                                } data-fulltext={result.question} >{result.question.slice(0, 11) + ' - ' + result.session_name.slice(0, 21)} </span>
                                <LaunchIcon
                                  style={{ cursor: 'pointer', fontSize: '14px' }}
                                  data-fulltext={result.question}
                                  onClick={(event) => { event.stopPropagation(); sendQuestionNew(event); }}
                                />
                              </Typography>      </div>
                          ))}
                        </div>

                      ) : (
                        <div  style={{width: 280,maxHeight: 200,overflow: 'auto',border: '1px solid #CCCCCC',borderRadius: '6px',backgroundColor: '#FFFFFF',position: 'relative',
                        padding:'5px',fontWeight:'bold'}}>No results found</div>
                      )
                      }
                    </>)}
                  <Popover
                    open={openPopover}
                    anchorEl={popoverAnchorEl}
                    onClose={handlePopoverClose}
                    // onMouseLeave = {handlePopoverLeave}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    sx={{
                      '& .MuiPopover-paper': {
                        width: '31%',
                        marginLeft: '6%'
                      },
                    }}
                  ><div onMouseLeave={handlePopoverLeave} >
                      <Typography sx={{ p: 2 }}>
                        <span style={{ fontWeight: 'bold' }}>{tooltipQuestion}</span>
                        <br /> <br />
                        <span>{(tooltipAnswer).replace(/<\/?[^>]+(>|$)/g, '')}</span>
                      </Typography></div>
                  </Popover>


                  {Object.keys(selectedMonth).map((month) => (
                    <div key={month}>
                      <Typography variant="h6" sx={{ paddingTop: '16px', color: '#898989', fontSize: '12px' }}>
                        <button

                          style={{
                            backgroundColor: 'inherit',
                            border: 'none',
                            fontSize: '14px',
                            color: 'black',
                            fontWeight: 'bold'
                          }}

                        >
                          {month}
                          {selectedMonthName === month ? (
                            <ExpandLessIcon
                              onClick={() => handleMonthClick(month)}
                              style={{ marginLeft: '5px' }}
                            />
                          ) : (
                            <ExpandMoreIcon
                              onClick={() => handleMonthClick(month)}
                              style={{ marginLeft: '5px' }}
                            />
                          )}
                        </button>
                      </Typography>

                      {selectedMonthName === month && selectedMonth[month] && (
                        <List>
                          {selectedMonth[month].map((session: any, index: any) => (
                            <Accordion
                              key={index}
                              sx={{ boxShadow: 'none', contentVisibility: 'auto' }}
                              expanded={expandedAccordion2 === `panelse-${month}-${index + 1}`}
                              onChange={handleChange2(`panelse-${month}-${index + 1}`)}
                            >

                              <AccordionSummary
                                aria-controls={`panelse-${month}-${index + 1}-content`}
                                id={`panelse-${month}-${index + 1}-header`}
                                sx={{
                                  backgroundColor: expandedAccordion2 === `panelse-${month}-${index + 1}` ? '#C9EAE2' : 'inherit',
                                  borderRadius: '6px',
                                  width: '95%',
                                }}
                                onClick={(event) => handleAccordionClick2(session.session_id, event)}
                              >
                                <ListItemIcon sx={{ color: 'black' }}>
                                  <FolderIcon />
                                </ListItemIcon>
                                {editingIndex === index ? (
                                  <>
                                    <input
                                      ref={inputRef}
                                      style={{ marginLeft: '-26px' }}
                                      type="text"
                                      value={editedSessionName}
                                      onClick={(e) => e.stopPropagation()}
                                      onChange={(e) => setEditedSessionName(e.target.value)}
                                      onBlur={(event) => handleSaveClick2(session.session_id, event)}
                                    />

                                  </>
                                ) : (
                                  <>
                                    <Tooltip title={session.session_name}>
                                      <Typography sx={{ marginRight: 'auto', marginLeft: '-23px' }}>
                                        {session.session_name.slice(0, 19)}
                                      </Typography></Tooltip>
                                    {expandedAccordion2 === `panelse-${month}-${index + 1}` && (
                                      <>
                                        <EditIcon sx={{ marginLeft: '11px', cursor: 'pointer' }} onClick={(e) => handleEditClick2(e, index, session.session_name)} />
                                        <DeleteIcon sx={{ marginLeft: '7px', cursor: 'pointer' }} onClick={(event) => handleDeleteClick2(session.session_id, session.session_name, index, month, event)} />
                                      </>
                                    )}
                                  </>
                                )}
                              </AccordionSummary>
                              <AccordionDetails>
                                <div>
                                  {sessionData.map((qas: any, qaIndexs: number) => (
                                    <div key={qaIndexs}>
                                      <Typography sx={{ borderBottom: '1px solid #ccc', lineHeight: '2.0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <span style={{ cursor: 'pointer' }} onMouseOver={(event) =>
                                          handleQuestionHover(qas.question, qas.answer, event)
                                        } data-fulltext={qas.question}
                                        >{qas.question.slice(0, 25)}</span><LaunchIcon style={{ cursor: 'pointer' }}
                                          data-fulltext={qas.question} onClick={(event) => sendQuestionNew(event)}
                                        />
                                      </Typography>
                                    </div>
                                  ))}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          ))}
                          <Popover
                            open={openPopover}
                            anchorEl={popoverAnchorEl}
                            onClose={handlePopoverClose}
                            // onMouseLeave = {handlePopoverLeave}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            sx={{
                              '& .MuiPopover-paper': {
                                width: '31%',
                                marginLeft: '6%'
                              },
                            }}
                          ><div onMouseLeave={handlePopoverLeave} >
                              <Typography sx={{ p: 2 }}>
                                <span style={{ fontWeight: 'bold' }}>{tooltipQuestion}</span>
                                <br /> <br />
                                <span>{(tooltipAnswer).replace(/<\/?[^>]+(>|$)/g, '')}</span>
                              </Typography></div>
                          </Popover>
                        </List>
                      )}
                    </div>
                  ))}



                </div>

              ) : (
                // Render content for recent history
                <div>
                  <Paper
                    component="form"
                    sx={{
                      width: 280,
                      height: 32,
                      backgroundColor: '#F0F0F0',
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: '6px',
                      position: 'relative',
                      top: '5px',
                    }}
                  >
                    <InputBase
                      placeholder="Search"
                      sx={{
                        ml: 1,
                        flex: 1,
                        height: '100%',
                        padding: '0 8px',
                        fontSize: '14px',
                      }}
                      inputProps={{ 'aria-label': 'search' }}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      onKeyDown={handleKeyboardEvent1}
                    />
                    <IconButton
                      type="button"
                      sx={{
                        p: '8px',
                        borderRadius: 0,
                        '&:hover': {
                          backgroundColor: '#D0D0D0',
                        },
                      }}
                      aria-label="search"
                      onClick={() => handleSearch(searchQuery)}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                  {search && (
                        <>
                  {searchResults.length > 0 ? (
                    <div
                      style={{
                        width: 280,
                        maxHeight: 200,
                        overflow: 'auto',
                        border: '1px solid #CCCCCC',
                        borderRadius: '6px',
                        backgroundColor: '#FFFFFF',
                        position: 'relative',
                        top: '13px',
                        overflowY: 'auto',
                        overflowX: 'hidden' // Adjust this value based on your design
                      }}
                    >
                      
                          {searchResults.map((result: any, index) => (
                            <div key={index} >
                              < Typography style={{ padding: '8px', borderBottom: '1px solid #CCCCCC', fontSize: '12px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <span style={{ cursor: 'pointer', fontSize: '11px' }} onMouseOver={(event) =>
                                  handleQuestionHover(result.question, result.answer, event)
                                } data-fulltext={result.question} >{result.question.slice(0, 11) + ' - ' + result.session_name.slice(0, 21)} </span>
                                <LaunchIcon
                                  style={{ cursor: 'pointer', fontSize: '14px' }}
                                  data-fulltext={result.question}
                                  onClick={(event) => { event.stopPropagation(); sendQuestionNew(event); }}
                                />
                              </Typography>      </div>
                          ))}
                        </div>

                      ) :(
                      <div style={{width: 280,maxHeight: 200,overflow: 'auto',border: '1px solid #CCCCCC',borderRadius: '6px',backgroundColor: '#FFFFFF',position: 'relative',
                        padding:'5px',top:'10px',fontWeight:'bold'}}>No results found</div>
                      )
                  }
                    </>)}
                  <Popover
                    open={openPopover}
                    anchorEl={popoverAnchorEl}
                    onClose={handlePopoverClose}

                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    sx={{
                      '& .MuiPopover-paper': {
                        width: '31%',
                        marginLeft: '6%'
                      },
                    }}
                  ><div onMouseLeave={handlePopoverLeave} >
                      <Typography sx={{ p: 2 }}>
                        <span style={{ fontWeight: 'bold' }}>{tooltipQuestion}</span>
                        <br /> <br />
                        <span>{(tooltipAnswer).replace(/<\/?[^>]+(>|$)/g, '')}</span>
                      </Typography></div>
                  </Popover>

                  <List sx={{ paddingTop: '31px' }}>
                    <React.Fragment>
                      <Typography variant="h6" gutterBottom sx={{ color: '#898989', fontSize: '12px' }}>
                        {'Today'}
                      </Typography>
                      {/* Accordion */}
                      {Object.values(chatData).map((session: any, sessionIndex) => (
                        <Accordion
                          key={sessionIndex}
                          sx={{ boxShadow: 'none', contentVisibility: 'auto' }}
                          expanded={expandedAccordion1 === `panel${sessionIndex + 1}`}
                          onChange={handleChange1(`panel${sessionIndex + 1}`)}
                        >
                          <AccordionSummary
                            aria-controls={`panel${sessionIndex + 1}-content`}
                            id={`panel${sessionIndex + 1}-header`}
                            sx={{
                              backgroundColor: expandedAccordion1 === `panel${sessionIndex + 1}` ? '#C9EAE2' : 'inherit',
                              borderRadius: '6px',
                              width: '95%',
                            }}
                          >
                            <ListItemIcon sx={{ color: 'black' }}>
                              <FolderIcon />

                            </ListItemIcon>
                            {editingTodayIndex === sessionIndex ? (
                              <>
                                <input
                                  ref={inputRef}
                                  style={{ marginLeft: '-26px' }}
                                  type="text"
                                  value={editedTodaySessionName}
                                  onClick={(e) => e.stopPropagation()}
                                  onChange={(e) => setEditedTodaySessionName(e.target.value)}
                                  onBlur={(event) => handleSaveClick(session.session_id, event)}
                                />

                              </>
                            ) : (
                              <>
                                <Tooltip title={session.session_name}>
                                  <Typography sx={{ marginRight: 'auto', marginLeft: '-23px' }}>
                                    {session.session_name.slice(0, 19)}
                                  </Typography></Tooltip>
                                {expandedAccordion1 === `panel${sessionIndex + 1}` && (
                                  <>
                                    <EditIcon sx={{ marginLeft: '11px', cursor: 'pointer' }} onClick={(e) => handleEditClick(e, sessionIndex, session.session_name)} />
                                    <DeleteIcon sx={{ marginLeft: '7px', cursor: 'pointer' }} onClick={(event) => handleDeleteClick(session.session_id, session.session_name, event)} />
                                  </>
                                )}
                              </>
                            )}
                          </AccordionSummary>


                          <AccordionDetails>
                            <div>
                              {session.data.map((qa: any, qaIndex: number) => (
                                <div key={qaIndex}>
                                  <Typography sx={{ borderBottom: '1px solid #ccc', lineHeight: '2.0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <span
                                      style={{ cursor: 'pointer' }}

                                      onMouseOver={(event) => {
                                        handleQuestionHover(qa.question, qa.answer, event);
                                      }}


                                    >
                                      {qa.question.slice(0, 25)}

                                    </span>

                                    <LaunchIcon style={{ cursor: 'pointer' }} data-fulltext={qa.question} onClick={(event) => sendQuestionNew(event)} />

                                  </Typography>

                                </div>
                              ))}
                            </div>

                          </AccordionDetails>
                        </Accordion>
                      ))}

                      <Popover
                        open={openPopover}
                        anchorEl={popoverAnchorEl}
                        onClose={handlePopoverClose}
                        // onMouseLeave = {handlePopoverLeave}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        sx={{
                          '& .MuiPopover-paper': {
                            width: '31%',
                            marginLeft: '6%'
                          },
                        }}
                      ><div onMouseLeave={handlePopoverLeave} >
                          <Typography sx={{ p: 2 }}>
                            <span style={{ fontWeight: 'bold' }}>{tooltipQuestion}</span>
                            <br /> <br />
                            <span>{(tooltipAnswer).replace(/<\/?[^>]+(>|$)/g, '')}</span>
                          </Typography></div>
                      </Popover>
                    </React.Fragment>
                  </List>
                  <List>

                    <Typography variant="h6" sx={{ paddingTop: '16px', color: '#898989', fontSize: '12px' }}>
                      Past 7 Days
                    </Typography>
                    {allSessions.map((session: any, index) => (
                      <Accordion
                        key={index}
                        sx={{ boxShadow: 'none', contentVisibility: 'auto' }}
                        expanded={expandedAccordion === `panels${index + 1}`}
                        onChange={handleChange(`panels${index + 1}`)}

                      // onClick={onFolderClick()}
                      >
                        <AccordionSummary
                          aria-controls={`panels${index + 1}-content`}
                          id={`panels${index + 1}-header`}
                          sx={{
                            backgroundColor: expandedAccordion === `panels${index + 1}` ? '#C9EAE2' : 'inherit',
                            borderRadius: '6px',
                            width: '95%',

                          }}
                          //onClick={(event) => handleAccordionClick(session.session_id, event)}
                          onClick={(event) => handleAccordionClick(session.session_id, event)}
                        >
                          <ListItemIcon sx={{ color: 'black' }}>
                            <FolderIcon />
                          </ListItemIcon>
                          {editingPast7DaysIndex === index ? (
                            <>
                              <input
                                ref={inputRefi}
                                style={{ marginLeft: '-26px' }}
                                type="text"
                                value={editedPast7DaysSessionName}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => setEditedPast7DaysSessionName(e.target.value)}
                                onBlur={(event: React.FocusEvent<HTMLInputElement>) =>
                                  handleSaveClick1(session.session_id, event)
                                }
                              />

                            </>
                          ) : (
                            <>
                              <Tooltip title={session.session_name}>
                                <Typography sx={{ marginRight: 'auto', marginLeft: '-23px' }}>
                                  {session.session_name.slice(0, 19)}
                                </Typography></Tooltip>

                              {expandedAccordion === `panels${index + 1}` && (
                                <>
                                  <EditIcon sx={{ marginLeft: '11px', cursor: 'pointer' }} onClick={(e) => handleEditClick1(e, index, session.session_name)} />
                                  <DeleteIcon sx={{ marginLeft: '7px', cursor: 'pointer' }} onClick={(event) => handleDeleteClick1(session.session_id, session.session_name, index, event)} />
                                </>
                              )}
                            </>
                          )}
                        </AccordionSummary>
                        <AccordionDetails>

                          <div>
                            {sessionData.map((qas: any, qaIndexs: number) => (
                              <div key={qaIndexs}>
                                <Typography sx={{ borderBottom: '1px solid #ccc', lineHeight: '2.0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                  <Tooltip
                                    title={
                                      <div style={{ maxHeight: '350px', overflowY: 'auto' }}>
                                        <Typography sx={{ p: 2, backgroundColor: "white", color: "black" }}>
                                          <span style={{ fontWeight: 'bold' }}>{qas.question}</span>
                                          <br /> <br />
                                          <span>{qas.answer.replace(/<\/?[^>]+(>|$)/g, '')}</span>
                                        </Typography>
                                      </div>
                                    }
                                    arrow
                                    enterDelay={500}
                                  >
                                    <span
                                      style={{ cursor: 'pointer' }}
                                      onMouseOver={(event) => {
                                        handleQuestionHover(qas.question, qas.answer, event);
                                      }}
                                      onMouseOut={handleMouseOut}
                                      data-fulltext={qas.question}
                                    >
                                      {qas.question.slice(0, 25)}
                                    </span>
                                  </Tooltip>

                                  <LaunchIcon style={{ cursor: 'pointer' }}
                                    data-fulltext={qas.question} onClick={(event) => sendQuestionNew(event)}
                                  />
                                </Typography>

                              </div>
                            ))}
                          </div>
                        </AccordionDetails>

                      </Accordion>
                    ))}
                    {/* <Popover
        open={openPopover1}
        anchorEl={popoverAnchorEl1} 
        onClose={handlePopoverClose1}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{  '& .MuiPopover-paper': {
          width: '31%', 
          marginLeft:'9%'
        }, }}
        >
        <Typography onMouseOver={()=> setHover(true)} onMouseOut={()=> setHover(false)} style={{backgroundColor:hover ? 'lightblue':'transparent' }}sx={{ p: 2 }}>
     <span style={{fontWeight:'bold'}}>{tooltipQuestion1}</span>
    <br /> <br />
    <span>{(tooltipAnswer1).replace(/<\/?[^>]+(>|$)/g, '')}</span>
  </Typography>
      </Popover> */}

                  </List>
                </div>
              )}
            </div>
          </div>


        </Drawer_new>

        {/* <HistoryDrawerComponent setQuestion={setQuestionFromHistory} /> */}
      </Box >

    );

  }
  return (

    <div className="container-fluid" style={{ display: "flex" }}>
      {/* <MiniDrawerComponent /> */}
      {/* <HistoryDrawerComponent setQuestion={setQuestionFromHistory} /> */}
      {isMobile ? null : renderMiniDrawer()}

      <div style={{ flex: 1, marginTop: "64px" }}>

        {loadingState !== "error" && (
          <div className={`row imgnew ${isDrawerOpen1 ? 'rightpanel' : ''}`}>
            <HistoryDrawerComponent setQuestion={setQuestionFromHistory} onDrawerStateChange={handleDrawerStateChange} />
            {(!questionReducer.questions ||
              questionReducer.questions.length === 0) && (
                <WelcomeScreenComponent isDrawerOpen1 />
              )}
            {questionReducer.questions &&
              questionReducer.questions.length > 0 && (
                <QuestionSectionComponent updateRating={updateQuestionRating} sendQuestion={sendQuestion}  isDrawerOpen2={isDrawerOpen1}/>
              )}
            <div className={isMobile ? "col-10 text-center question-div mobile-question-bar " : "col-8 offset-2 text-center question-div question-bar "}>
              <Paper
                elevation={3}
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
                className="rounded"
              >
                <InputBase
                  sx={{ ml: 3, flex: 1 }}
                  multiline={true}
                  placeholder={questionPlaceHolder}
                  inputProps={{
                    "aria-label": questionPlaceHolder,
                    id: "",
                  }}
                  onChange={onQuestionValueChange}
                  onKeyUp={handleKeyBoardEvent}
                  onKeyDown={handleKeyBoardEvent}
                  value={questionText}
                  name="Question"
                  autoFocus
                  autoComplete="off"
                />
                <IconButton
               id="send-button"
                  type="button"
                  sx={{ p: "15px" }}
                  aria-label="search"
                  onClick={sendQuestion}
                  color="success"
                  disabled={isDisabled}
                >
                  <SendIcon />
                </IconButton>
                {/* <HtmlTooltip
                  title="Initiating a new conversation will clear your existing conversation."
                  placement="right"
                  enterDelay={1000}
                  arrow
                >
                  <span>
                    <IconButton
                      type="button"
                      sx={{ p: "15px" }}
                      aria-label="New Conversation"
                      onClick={newConversation}
                      color="secondary"
                      disabled={
                        !questionReducer.questions ||
                        questionReducer.questions.length === 0
                      }
                    >
                      <AddCommentIcon />
                    </IconButton>
                  </span>
                </HtmlTooltip>  */}
              </Paper>

            </div>
          </div>

        )}

        {loadingState === "loading" && <LoaderComponent />}
        <CustomSnackbar
          isDrawerOpen2={isDrawerOpen1}
          openSnackbar={openSnackbar}
          type={snackbarType}
          message={snackbarMessage}
          handleSnackBarClose={handleSnackbarClose}
        />

      </div>
    </div >
  );
};


export default HomeComponent;
