import React from 'react';
import { ChangeEventHandler } from 'react';
import { Box, Grid } from '@mui/material';
import { Button } from "@mui/material";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Form from 'react-bootstrap/Form';
import dayjs, { Dayjs } from 'dayjs';


 const DateRangePicker = ({ handleDropVal, mode, startdate, enddate, setStartDate, setEndDate, submitClicked, handleSubmit, flag }: {
    handleDropVal: (e: { target: { value: string } }) => void;
    mode: string;
    startdate: Dayjs; // Change to Dayjs
    enddate: Dayjs; // Change to Dayjs
    setStartDate: React.Dispatch<React.SetStateAction<Dayjs>>; // Change to Dayjs
    setEndDate: React.Dispatch<React.SetStateAction<Dayjs>>; // Change to Dayjs
    submitClicked: React.Dispatch<React.SetStateAction<boolean>>;
    handleSubmit: (startdate: Dayjs, enddate: Dayjs) => void;
    flag: number;
  }) => {
    return (
        <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1}>
                        {/* <Grid item xs={5}>
                            <Item style={{ textAlign: 'left' }}><div>Welcome,</div><div><h2>{username}</h2></div></Item>
                        </Grid> */}
                        <Grid item xs={2}>

                            <Form.Select aria-label="Month" style={{ marginTop: "9px", height: "50px" }} onChange={handleDropVal}>
                                <option value="custom">Custom Date Range</option>
                                <option value="day">Today</option>
                                <option value="month">This Month</option>
                                {/* <option value="Day">Custom</option> */}

                            </Form.Select>

                        </Grid>
                        <Grid item xs={6}>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                <DatePicker
    label="Start Date"
    value={startdate}
    onChange={(newval) => {
        submitClicked(false);
        if (newval !== null) { // Check if newval is not null
            setStartDate(newval);
        }
    }}
    disabled={mode === 'custom' ? false : true}
/>

<DatePicker
    label="End Date"
    value={enddate}
    onChange={(newval) => {
        submitClicked(false);
        if (newval !== null) { 
            setEndDate(newval);
        }
    }}
    disabled={mode === 'custom' ? false : true}
/>
                                </DemoContainer>
                            </LocalizationProvider>

                        </Grid>

                        {(mode == 'custom') ?
                            <Grid item xs={2}>
                                <Button disabled={flag == 1 ? true : false} onClick={() => handleSubmit(startdate, enddate)} sx={{ backgroundColor: 'green', color: 'white', borderRadius: '6px', top: '18%', paddingRight: '28px', paddingLeft: '24px', paddingTop: '18px', right: '25%' }} type="submit" variant="contained" color="primary">
                                    <span style={{ top: '-7px', position: 'relative' }}> Submit</span>
                                </Button>
                            </Grid> : null}
                        <Grid item xs={2}>
                            {flag == 1 && <span className="errormsg">Start Date should less than End Date.</span>}
                        </Grid>
                    </Grid>
                </Box>
    );
};

export default DateRangePicker;



