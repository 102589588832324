import "./index.scss";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import msalInstance, { authRequest } from "./msalInstance";

import ActiveUserWrapper from "./components/ActiveUserWrapper/ActiveUserWrapper";
import App from "./App";
import FullScreenText from "./components/FullScreenText";
import { InteractionType } from "@azure/msal-browser";
import { Provider } from 'react-redux';
import React from "react";
import ReactDOM from "react-dom/client";
import { pdfjs } from "react-pdf";
import reportWebVitals from './reportWebVitals';
import { store } from './stores/store';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

const rootNode = document.getElementById("root");
if (!rootNode) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(rootNode);

//console.log(msalInstance);

root.render(
  
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={() => (
           <FullScreenText>Authentication Error</FullScreenText>
    //       <Provider store={store}>
    //   <App />
    // </Provider>
        )}
        loadingComponent={() => (
          <FullScreenText>
            Checking Authorization
          </FullScreenText>
        )}
      >
        <ActiveUserWrapper>
          <QueryClientProvider client={new QueryClient()}>
          <Provider store={store}>
      <App />
     </Provider>
          </QueryClientProvider>
        </ActiveUserWrapper>
      </MsalAuthenticationTemplate>
    </MsalProvider>

   
 );

// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </React.StrictMode>
// );
