
import * as React from 'react';
import "../dashboard.component.scss";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Form from 'react-bootstrap/Form';
import { Button } from "@mui/material";
import DateRangePicker from '../sessions/datepicker';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
//import SearchBar from "material-ui-search-bar";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getDashboardtopicService, getReportService } from "../../../services/dashboard.service";
import LoaderComponent from "../../loader/loader.component";
import moment from 'moment';
import dayjs, { Dayjs } from 'dayjs';
import { auto } from '@popperjs/core';

const drawerWidth = 180;
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Topics = () => {
    const [time, setTime] = React.useState('');
    const [flag, setFlag] = React.useState(0);
    const [mode, setMode] = React.useState('custom');
    const [report, setReportname] = React.useState('');
    const [reportlink, setReportLink] = React.useState('');
    const [submitClick, submitClicked] = React.useState(true);
    const [loadingState, updateLoadingState] = React.useState("loading");
    const [startdate, setStartDate] = React.useState<Dayjs>(dayjs());
    const [enddate, setEndDate] = React.useState<Dayjs>(dayjs());
    const [tableres, setTableres] = React.useState([{
        count: 0,
        topic: 'topic',
    }]);
   
    const [userInfo, setUserInfo] = React.useState({
        userId: "test@sce.com"
    });
    const handleChange = (event: SelectChangeEvent) => {
        setTime(event.target.value as string);
    };

    const name = sessionStorage.getItem('username');
    var username = "";
    if (name != null && name != undefined) {
        username = name?.split(' ')[0];
    }

    const handleDropVal = (e: { target: { value: any; }; }) => {
        setMode(e.target.value);
        submitClicked(true);
    }
    const handleSubmit = (startdate: any, enddate: any) => {
        updateLoadingState("loading");
        const custom_startdate = startdate ? startdate.startOf('day').format("MM/DD/YYYY HH:mm:ss") : dayjs().startOf('day').format("MM/DD/YYYY HH:mm:ss");
        const custom_enddate = enddate ? enddate.endOf('day').format("MM/DD/YYYY HH:mm:ss") : dayjs().endOf('day').format("MM/DD/YYYY HH:mm:ss");
        getReportService(userInfo.userId, custom_startdate, custom_enddate, mode).then((res: any) => {

            setReportLink(res.data.filepath);
updateLoadingState("success");
        })
            .catch((_err: any) => { });
        getDashboardtopicService(custom_startdate, custom_enddate, mode).then((res: any) => {
           
            submitClicked(true);
           setTableres(res.data);
        })
            .catch((_err: any) => { });
    };

    React.useEffect(() => {
        const start_date = mode == "day" ? moment().startOf('day').format("MM/DD/YYYY HH:mm:ss") : moment().startOf('month').format("MM/DD/YYYY HH:mm:ss");
        const end_date = moment().endOf('day').format("MM/DD/YYYY HH:mm:ss");
        const custom_startdate = startdate ? startdate.startOf('day').format("MM/DD/YYYY HH:mm:ss") : dayjs().startOf('day').format("MM/DD/YYYY HH:mm:ss");
        const custom_enddate = enddate ? enddate.endOf('day').format("MM/DD/YYYY HH:mm:ss") : dayjs().endOf('day').format("MM/DD/YYYY HH:mm:ss");

        
        getReportService(userInfo.userId, start_date, end_date, mode).then((res: any) => {

            setReportLink(res.data.filepath);

        })
            .catch((_err: any) => { });
       
        if (submitClick) {
            updateLoadingState("loading");

            getDashboardtopicService(start_date, end_date, mode).then((res: any) => {
              
                setTableres(res.data);
                updateLoadingState("success");
            })
                .catch((_err: any) => { });
        }
    }, [mode]);


    const chartSetting = {
        xAxis: [
            {
                label: 'Topic Count',
            },
        ],
        width: 500,
        height: 400,
    };

    React.useEffect(() => {
        if (mode == "day") {
            setStartDate(dayjs().startOf('day'));
            setEndDate(dayjs().endOf('day'));
        } else if (mode == "month") {
            setStartDate(dayjs().startOf('month'));
            setEndDate(dayjs().endOf('day'));
        } else {
            setStartDate(dayjs().startOf('month'));
            setEndDate(dayjs().endOf('day'));
        }

    }, [mode]);

    React.useEffect(() => {
        const startDate = mode === "day" ? moment().startOf('day') : moment().startOf('month');
        const endDate = moment().endOf('day');
        const customStartDate = startdate ? moment(startdate.toDate()).startOf('day') : moment().startOf('day');
        const customEndDate = enddate ? moment(enddate.toDate()).endOf('day') : moment().endOf('day');

        if (customStartDate.isAfter(customEndDate)) {
            setFlag(1);
        } else {
            setFlag(0);
        }

        if (mode === 'custom') {
            setReportname(`Topics Report for ${customStartDate.format("MM/DD/YYYY")} - ${customEndDate.format("MM/DD/YYYY")}`);
        } else if (mode === 'day') {
            setReportname(`Topics Report for Today (${moment().startOf('day').format("MM/DD/YYYY")})`);
        } else {
            setReportname(`Topics Report for This month (${moment().format("MMM YYYY")})`);
        }
    }, [startdate, enddate])
    return (
        <>
            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
            >


<DateRangePicker 
                handleDropVal={handleDropVal} 
                mode={mode} 
                startdate={startdate} 
                enddate={enddate} 
                setStartDate={setStartDate} 
                setEndDate={setEndDate} 
                submitClicked={submitClicked} 
                handleSubmit={handleSubmit} 
                flag={flag} 
            />
                <h5 style={{ marginTop: "15px" }}><b>Reports</b></h5>
                <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                    <span>{report}
                        <a href={reportlink} download>
                            <DownloadForOfflineIcon />
                        </a>
                    </span>
                </div>
                {/* <h5 style={{ marginTop: "15px" }}><b>Trends</b></h5> */}
                <div className="flex-container row">
                    <div className="col-6 chart">
                        <div className="usersperhour" style={{ height: 445, overflowY: auto }}>
                            <table className="table topictable">
                                <thead>
                                    <tr>
                                        <th>Topic</th>
                                        <th>Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableres.map((data) => {
                                        return (
                                            <tr>
                                                <td>{data.topic}</td>
                                                <td>{data.count.toLocaleString()}</td>
                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Box>
            {loadingState === "loading" && <LoaderComponent />}
        </>
    )
}

export default Topics;
