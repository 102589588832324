

import * as React from 'react';
import "../dashboard.component.scss";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { axisClasses } from '@mui/x-charts';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Form from 'react-bootstrap/Form';
import DateRangePicker from './datepicker';
import { Button } from "@mui/material";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Select, { SelectChangeEvent } from '@mui/material/Select';
//import SearchBar from "material-ui-search-bar";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getDashboardsessionService, getReportService } from "../../../services/dashboard.service";
import LoaderComponent from "../../loader/loader.component";
import moment from 'moment';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { red } from '@mui/material/colors';

const drawerWidth = 180;
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Sessions = () => {
    const [time, setTime] = React.useState('');
    const [flag, setFlag] = React.useState(0);
    const [mode, setMode] = React.useState('custom');
    const [report, setReportname] = React.useState('');
    const [reportlink, setReportLink] = React.useState('');
    const [submitClick, submitClicked] = React.useState(true);
    const [loadingState, updateLoadingState] = React.useState("loading");
    const [startdate, setStartDate] = React.useState<Dayjs>(dayjs());
    const [enddate, setEndDate] = React.useState<Dayjs>(dayjs());
    const [res, setRes] = React.useState({
        number_of_question: [0],
        x_axis: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        months: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        number_of_session: [0],
        number_of_users: [0],
        question_count: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        sessions_count: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        users_count: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    });
    const [userInfo, setUserInfo] = React.useState({
        userId: "test@sce.com"
    });
    const handleChange = (event: SelectChangeEvent) => {
        setTime(event.target.value as string);
    };

    const name = sessionStorage.getItem('username');
    var username = "";
    if (name != null && name != undefined) {
        username = name?.split(' ')[0];
    }

    const handleDropVal = (e: { target: { value: any; }; }) => {
        setMode(e.target.value);
        submitClicked(true);
    }
    const handleSubmit = (startdate: any, enddate: any) => {
        updateLoadingState("loading");
        const custom_startdate = startdate ? startdate.startOf('day').format("MM/DD/YYYY HH:mm:ss") : dayjs().startOf('day').format("MM/DD/YYYY HH:mm:ss");
        const custom_enddate = enddate ? enddate.endOf('day').format("MM/DD/YYYY HH:mm:ss") : dayjs().endOf('day').format("MM/DD/YYYY HH:mm:ss");
        getReportService(userInfo.userId, custom_startdate, custom_enddate, mode).then((res: any) => {

            setReportLink(res.data.filepath);
            updateLoadingState("success");
        }).catch((_err: any) => { });
        getDashboardsessionService(custom_startdate, custom_enddate, mode).then((res: any) => {
            setRes(res.data);
            submitClicked(true);
            
        })
            .catch((_err: any) => { });
    };

    React.useEffect(() => {
        const start_date = mode == "day" ? moment().startOf('day').format("MM/DD/YYYY HH:mm:ss") : moment().startOf('month').format("MM/DD/YYYY HH:mm:ss");
        const end_date = moment().endOf('day').format("MM/DD/YYYY HH:mm:ss");
        const custom_startdate = startdate ? startdate.startOf('day').format("MM/DD/YYYY HH:mm:ss") : dayjs().startOf('day').format("MM/DD/YYYY HH:mm:ss");
        const custom_enddate = enddate ? enddate.endOf('day').format("MM/DD/YYYY HH:mm:ss") : dayjs().endOf('day').format("MM/DD/YYYY HH:mm:ss");

        getReportService(userInfo.userId, start_date, end_date, mode).then((res: any) => {

            setReportLink(res.data.filepath);

        })
            .catch((_err: any) => { });

        if (submitClick) {
            updateLoadingState("loading");
            getDashboardsessionService(start_date, end_date, mode).then((res: any) => {
                setRes(res.data);
                updateLoadingState("success");
            })
                .catch((_err: any) => { });
        }
    }, [mode]);

    React.useEffect(() => {
        if (mode == "day") {
            setStartDate(dayjs().startOf('day'));
            setEndDate(dayjs().endOf('day'));
        } else if (mode == "month") {
            setStartDate(dayjs().startOf('month'));
            setEndDate(dayjs().endOf('day'));
        } else {
            setStartDate(dayjs().startOf('month'));
            setEndDate(dayjs().endOf('day'));
        }

    }, [mode]);

    React.useEffect(() => {
       const startDate = mode === "day" ? moment().startOf('day') : moment().startOf('month');
        const endDate = moment().endOf('day');
        const customStartDate = startdate ? moment(startdate.toDate()).startOf('day') : moment().startOf('day');
        const customEndDate = enddate ? moment(enddate.toDate()).endOf('day') : moment().endOf('day');

        if (customStartDate.isAfter(customEndDate)) {
            setFlag(1);
        } else {
            setFlag(0);
        }

        if (mode === 'custom') {
            setReportname(`Session Report for ${customStartDate.format("MM/DD/YYYY")} - ${customEndDate.format("MM/DD/YYYY")}`);
        } else if (mode === 'day') {
            setReportname(`Session Report for Today (${moment().startOf('day').format("MM/DD/YYYY")})`);
        } else {
            setReportname(`Session Report for This month (${moment().format("MMM YYYY")})`);
        }
    }, [startdate, enddate])
    return (
        <>
            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
            >


<DateRangePicker 
                handleDropVal={handleDropVal} 
                mode={mode} 
                startdate={startdate} 
                enddate={enddate} 
                setStartDate={setStartDate} 
                setEndDate={setEndDate} 
                submitClicked={submitClicked} 
                handleSubmit={handleSubmit} 
                flag={flag} 
            />
                <h5 style={{ marginTop: "15px" }}><b>Reports</b></h5>
                <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                    <span>{report}
                        <a href={reportlink} download>
                            <DownloadForOfflineIcon />
                        </a>
                    </span>
                </div>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2} justifyContent="left" alignItems="left" >
                        <Grid item xs={4}>
                            <Item style={{ textAlign: 'left' }}><b>Number of Sessions</b>
                                <div><span style={{ fontSize: "24px" }} ><b>{res.number_of_session.toLocaleString()}</b>
                                </span></div>
                            </Item>
                        </Grid>
                        <Grid item xs={4}>
                            <Item style={{ textAlign: 'left' }}><b>Number of Questions</b>
                                <div><span style={{ fontSize: "24px" }} ><b>{res.number_of_question.toLocaleString()}</b>
                                </span></div>
                            </Item>
                        </Grid>
                        <Grid item xs={4}>
                            <Item style={{ textAlign: 'left' }}><b>Number of Users</b>
                                <div><span style={{ fontSize: "24px" }} ><b>{res.number_of_users.toLocaleString()}</b>
                                </span></div>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
                <h5 style={{ marginTop: "15px" }}><b>Trends</b></h5>
                <div className="flex-container row">
                    <div className="col-6 chart">
                        <div className="sessionperhour">
                            <div className='chatname'><h6>Sessions Per {mode == 'day' ? 'Hour' : 'Month'}</h6></div>
                            {res.number_of_session.toLocaleString() !== '0' ?
                                <LineChart
                                    width={500}
                                    height={300}
                                    series={[{ data: res.sessions_count, area: true, showMark: true, label: "Sessions" }]}
                                    xAxis={[{ scaleType: 'point', data: res.x_axis, label: mode == "day" ? "Hours" : "Days" }]}
                                    yAxis={[
                                        {
                                            label: "Sessions"
                                        },
                                    ]}
                                    margin={{ left: 80 }}
                                    sx={{
                                        '.MuiLineElement-root': {
                                            display: 'none',
                                        },
                                        [`.${axisClasses.left} .${axisClasses.label}`]: {
                                            transform: 'translate(-25px, 0)',
                                        },
                                    }}
                                /> : <div className='chatname'><h6>No Data Available</h6></div>}

                        </div>
                    </div>
                    <div className="col-6 chart">
                        <div className="questionperhour">
                            <div className='chatname'><h6>Questions Per {mode == 'day' ? 'Hour' : 'Month'}</h6></div>
                            {res.number_of_question.toLocaleString() !== '0' ?
                                <BarChart
                                    xAxis={[
                                        {
                                            id: 'barCategories',
                                            data: res.x_axis,
                                            scaleType: 'band',
                                            label: mode == "day" ? "Hours" : "Days"
                                        },
                                    ]}
                                    yAxis={[
                                        {
                                            label: "Questions"
                                        },
                                    ]}
                                    series={[
                                        {
                                            data: res.question_count,
                                            label: "Questions"
                                        },
                                    ]}
                                    width={500}
                                    height={300}
                                    margin={{ left: 80 }}
                                    sx={{
                                        [`.${axisClasses.left} .${axisClasses.label}`]: {
                                            transform: 'translate(-25px, 0)',
                                        },
                                    }}
                                /> : <div className='chatname'><h6>No Data Available</h6></div>}
                        </div>
                    </div>
                </div>
                <div className="flex-container row">
                    <div className="col-6 chart">
                        <div className="usersperhour">
                            <div className='chatname'><h6>Users per {mode == 'day' ? 'Hour' : 'Month'}</h6></div>
                            {res.number_of_users.toLocaleString() !== '0' ?
                                <BarChart
                                    xAxis={[
                                        {
                                            id: 'barCategories',
                                            data: res.x_axis,
                                            scaleType: 'band',
                                            label: mode == "day" ? "Hours" : "Days"
                                        },
                                    ]}
                                    yAxis={[
                                        {
                                            label: "Users"
                                        },
                                    ]}
                                    series={[
                                        {
                                            data: res.users_count,
                                            label: "Users"
                                        },
                                    ]}
                                    width={500}
                                    height={300}
                                    margin={{ left: 80 }}
                                    sx={{
                                        [`.${axisClasses.left} .${axisClasses.label}`]: {
                                            transform: 'translate(-25px, 0)',
                                        },
                                    }}
                                /> : <div className='chatname'><h6>No Data Available</h6></div>}
                        </div>
                    </div>
                </div>


            </Box>
            {loadingState === "loading" && <LoaderComponent />}
        </>
    )
}

export default Sessions;
