import "./home/home.component.scss";

import React from "react";
import { Spinner } from "react-bootstrap";

const FullScreenText: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <div className="loading-screen" data-testid="loading-screen">
  <div className="loading-content">
    <Spinner animation="border" variant="light" data-testid="spinner" />
    <p className="loading-text">{children}</p>
  </div>
</div>
  );
};

export default FullScreenText;
